import * as api from './base';

export const login = (params) => {
    const path = '/users/login';
    return api.postRequest(path, params);
};

export const refreshSession = (params) => {
    const path = '/refresh_session';
    return api.postRequest(path, params);
};

export const createUser = (params) => {
    const path = '/users';
    return api.postRequest(path, params);
};

export const resetPassword = (params) => {
    const path = '/users/lost_password';
    return api.postRequest(path, params);
};

export const getUser = (params) => {
    const path = '/users';
    return api.getRequest(path, params);
};

export const updateUser = (params, id = '') => {
    const path = `/users`;
    const email = (id !== '') ? id : params.email;
    return api.putRequest(path, params, email);
};

export const uploadAvatar = (params, id = '') => {
    const path = `/users`;
    const email = (id !== '') ? id : params.email;
    return api.putRequest(path, params, email, api.configUpload);
};

export const confirmEmail = (params) => {
    const path = `/users/${params.email}/confirm`;
    return api.getRequest(path, `?code=${params.code}`);
};

export const updateStatusUser = (params) => {
    const path = `/users/${params.email}/update_status/${params.status}`;
    return api.postRequest(path);
};

export const removeAccount = (params) => {
    const path = `/users`;
    return api.deleteRequest(path, params.username);
};

export const isValidResetPassword = (params) => {
    const path = '/users/is_valid_reset_password';
    return api.postRequest(path, params);
};

export const getUsernameAvailable = (params) => {
    const path = '/users/get_username_available';
    return api.getRequest(path + api.parseQueryParams(params));
};

export const getEmailAvailable = (params) => {
    const path = '/users/get_email_available';
    return api.getRequest(path + api.parseQueryParams(params));
};

export const logout = () => {
    const path = '/logout';
    return api.getRequest(path);
};

export const createDeviceUuid = (params) => {
    const path = '/device_uuid';
    return api.postRequest(path, params);
};

export const getDeviceUuid = (params) => {
    const path = '/device_uuid/';
    return api.getRequest(path, params);
};

export const updateDeviceUuid = (params, uuid) => {
    const path = `/device_uuid`;
    return api.putRequest(path, params, uuid);
};

export const createUserInviteCode = (params) => {
    const path = '/create_user_invite_code';
    return api.postRequest(path, params);
};
