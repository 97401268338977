import Vue from 'vue';
import * as types from './types';

export const state = {
    menu: false,
    messages: '',
    connected: true,
    currentBackUrl: "",
    zoomScale: 100,
    zoomSettings: {
        pan: false,
        centralize: false,
        keep: false,
    },
    contentSizeStyle: "",
    isMobile: false,
    windowWidth: 0,
    windowHeight: 0,
    debugMessages: "",
    closeKeyboard: false,
    activateCondensedView: false,
    isExibeCarrinho: false,
    progressBar: 0,
    progressBarTotal: 0,
    mediaTemp: {},
    images: {},
    imageTypeSelected: "",
}

export const mutations = {
    [types.SET_MENU](state, data) {
        state.menu = data;
    },
    [types.SET_MESSAGES](state, data) {
        state.messages = data;
    },
    [types.SET_CONNECTED](state, data) {
        state.connected = data;
    },
    [types.SET_CURRENT_BACK_URL](state, data) {
        state.currentBackUrl = data;
    },
    [types.ZOOM_SCALE](state, data) {
        state.zoomScale = data;
    },
    [types.ZOOM_SETTINGS](state, data) {
        state.zoomSettings = data;
    },
    [types.SET_CONTENT_SIZE_STYLE](state, data) {
        state.contentSizeStyle = data;
    },
    [types.SET_IS_MOBILE](state, data) {
        state.isMobile = data;
    },
    [types.SET_WINDOW_WIDTH](state, data) {
        state.windowWidth = data;
    },
    [types.SET_WINDOW_HEIGHT](state, data) {
        state.windowHeight = data;
    },
    [types.SET_DEBUG_MESSAGES](state, data) {
        if (data === "") {
            state.debugMessages = "";
        } else {
            if (data !== state.debugMessages) {
                state.debugMessages += `${data} <br/>`;
            }
        }
    },
    [types.SET_CLOSE_KEYBOARD](state, data) {
        state.closeKeyboard = data;
    },
    [types.ACTIVATE_CONDENSED_VIEW](state, data) {
        state.activateCondensedView = data;
    },
    [types.SET_IS_EXIBE_CARRINHO](state, data) {
        state.isExibeCarrinho = data;
    },
    [types.PROGRESS_BAR](state, increment) {
        if (parseInt(increment) < 1) {
            state.progressBar = increment;
        } else {
            state.progressBar += increment;
        }
    },
    [types.PROGRESS_BAR_TOTAL](state, data) {
        state.progressBarTotal = data;
    },
    [types.INTERRUPT_UPLOAD](state, data) {
        state.interruptUpload = data;
    },
    [types.UPDATE_MEDIA_TEMP](state, data) {
        if (Object.prototype.hasOwnProperty.call(data, "updated_image_uuid")) {
            //
            Object.assign(state.images[data.event_type], data.media)
            if (state.currentIndex > -1 && state.images.length > 0) {
                Object.assign(state.images[state.currentIndex], data.media);
            } else if (state.images.length === 0) {
                Object.assign(state.images.push(data.media));
            }
        } else {
            if (typeof data.media !== "undefined") {
                const imageType = data.media.reference.subreference;
                Vue.set(state.images, imageType, data.media);
                state.imageTypeSelected = imageType;
                Vue.set(state.mediaTemp, imageType, data.media);
            }
        }
    },
    [types.CLEAR_MEDIA_TEMP](state) {
        for (let i = 0; i < state.mediaTemp.length; i++) {
            let media = state.mediaTemp[i];
            let indexImages = state.images.findIndex( o => o.uuid == media.uuid);
            state.images.splice(indexImages, 1);
        }
        state.mediaTemp = [];
    },
    [types.REMOVE_MEDIA_TEMP](state, params) {
        delete state.mediaTemp[params.imageType];
    },
    [types.SET_IMAGE_TYPE_SELECTED](state, data) {
        state.imageTypeSelected = data;
    },

};
