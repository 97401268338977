export const menu = state => state.menu;
export const messages = state => state.messages;
export const connected = state => state.connected;
export const currentBackUrl = state => state.currentBackUrl;
export const fingerZoom = state => state.fingerZoom;
export const zoomScale = state => state.zoomScale;
export const zoomSettings = state => state.zoomSettings;
export const contentSizeStyle = state => state.contentSizeStyle;
export const isMobile = state => state.isMobile;
export const windowWidth = state => state.windowWidth;
export const windowHeight = state => state.windowHeight;
export const debugMessages = state => state.debugMessages;
export const closeKeyboard = state => state.closeKeyboard;
export const activateCondensedView = state => state.activateCondensedView;
export const isExibeCarrinho = state => state.isExibeCarrinho;
export const progressBar = state => state.progressBar;
export const progressBarTotal = state => state.progressBarTotal;
export const mediaTemp = state => state.mediaTemp;
