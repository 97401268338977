import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/es5/locale/pt';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            background: "#F2F3F5",
            secondary: "#8099B2",
          }
        }
    },
    lang: {
        locales: { pt },
        current: 'pt',
    },
    icons: {
        iconfont: 'mdi',
    },
});
