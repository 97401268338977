import * as favoritoHelperAPI from '@/helpers/api/Favorito';
 import * as types from './types';

export const favoritar = async ({ commit, dispatch}, params) => {
    const result = await favoritoHelperAPI.favoritar(params)
          .then((response) => {
              commit(types.FAVORITO, response.data.favorito);
              return response.data;
          }).catch((e) => {
              console.log(e);
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};

export const getFavorito = async ({ commit, dispatch}, params) => {    
    const result = await favoritoHelperAPI.getFavorito(params)
          .then((response) => {
              commit(types.FAVORITO, response.data.favorito);
              return response.data;
          }).catch((e) => {
              console.log(e);
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};