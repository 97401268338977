export const obra = state => state.obra;
export const obraFichaTecnica = state => state.obraFichaTecnica;
export const obras = state => state.obras;
export const mediaTemp = state => state.mediaTemp;
export const sugestaoEndereco = state => state.sugestaoEndereco;
export const updatedUUID = state => state.updatedUUID;
export const updateImage = state => state.updateImage;
export const currentIndex = state => state.currentIndex;
export const images = state => state.images;
export const linguagensFilled = state => state.linguagensFilled;
export const localObras = state => state.localObras;
export const currentScroll = state => state.currentScroll;
export const currentSearch = state => state.currentSearch;
export const total = state => state.total;
export const enableChangeOrder = state => state.enableChangeOrder;
