import * as adminHelperAPI from '@/helpers/api/Admin';
import * as authHelperAPI from '@/helpers/api/Auth';
import * as types from './types';

export const getUsers = ({ commit }, params) => {
    adminHelperAPI.getUsers(params)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.SET_USERS, response.data.users);                
            }
        });
};

export const doAdminUpdateUser = ({ dispatch, commit }, params) => {
    let username = (typeof params.username !== 'undefined') ? params.username : params.get('username');
        const result = authHelperAPI.updateUser(params, username)
              .then((response) => {
                  if (response.data.status === "true") {
                    commit(types.SET_CURRENT_UPDATE_USER, response.data.usuario);
                    return true;
                  } else {
                      return response.data;
                  }
              }).catch((e) => {
                  dispatch(
                      'noticias/mensagemErro',
                      e.response.mensagem,
                      { root: true },
                  );
              });
    return result;
};

export const doUpdateStatusUser = ({ dispatch }, params) => {
    const result = authHelperAPI.updateStatusUser(params)
          .then((response) => {
              if (response.data.status === "true") {
                  return true;
              } else {
                  return response.data;
              }
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};


export const doCreateInviteCode = ({ commit, dispatch }, params) => {
    const result = adminHelperAPI.createInviteCode(params)
          .then((response) => {
              if (response.data.status === "true") {
                  commit(types.SET_INVITE_CODE, response.data.invite_code);
              }
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};

export const getInviteCodes = ({ commit, dispatch }, params) => {
    const result = adminHelperAPI.getInviteCodes(params)
          .then((response) => {
              if (response.data.status === "true") {
                  commit(types.SET_INVITE_CODES, response.data.invite_codes);
              }
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};

export const doUserAddProfile = ({ dispatch }, params) => {
  const result = adminHelperAPI.userAddProfile(params)
                               .then(response => {
                                 if (response.data.status === "true") {
                                   return true;
                                 }
                               }).catch((e) => {
                                 dispatch(
                                   'noticias/mensagemErro',
                                   e.response.mensagem,
                                   { root: true },
                                 );
                               })
  return result;
};
