export const SET_PERFIL = 'SET_PERFIL';
export const GET_ARTISTAS = 'GET_ARTISTAS';
export const GET_ARTISTA = 'GET_ARTISTA';
export const GET_OBRAS_PERFIL = 'GET_OBRAS_PERFIL';
export const GET_EVENTOS_PERFIL = 'GET_EVENTOS_PERFIL';
export const GET_EXPOSICOES_PERFIL = 'GET_EXPOSICOES_PERFIL';
export const GET_TEXTOS_PERFIL = 'GET_TEXTOS_PERFIL';
export const SET_CURRENT_SCROLL = 'SET_CURRENT_SCROLL';
export const SET_AVAILABLE_TABS = 'SET_AVAILABLE_TABS';
export const SET_EDITORIAL = 'SET_EDITORIAL';
