import Vue from 'vue';
import Router from 'vue-router';
import RotasPaginas from '@/modules/paginas/router';
import RotasAuth from '@/modules/auth/router';
import RotasArtista from '@/modules/artista/router';
import RotasExposicao from '@/modules/exposicao/router';
import RotasEvento from '@/modules/evento/router';
import RotasObra from '@/modules/obra/router';
import RotasTexto from '@/modules/texto/router';
import RotasLoja from '@/modules/loja/router';
import RotasAdmin from '@/modules/admin/router';
import RotasPerfis from '@/modules/perfis/router';
import RotasParceiro from '@/modules/parceiro/router';

Vue.use(Router);

const baseRoutes = [
    {
        path: '*',
        redirect: '/home'
    },
];

let routes = [];
routes = routes.concat(baseRoutes);
routes = routes.concat(RotasPaginas);
routes = routes.concat(RotasAuth);
routes = routes.concat(RotasArtista);
routes = routes.concat(RotasExposicao);
routes = routes.concat(RotasEvento);
routes = routes.concat(RotasObra);
routes = routes.concat(RotasTexto);
routes = routes.concat(RotasLoja);
routes = routes.concat(RotasAdmin);
routes = routes.concat(RotasPerfis);
routes = routes.concat(RotasParceiro);

var router = new Router({
    routes,
});

export default router;
