import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            deviceReady: false,
        };
    },
    computed: {
        ...mapGetters({
            currentBackUrl: "global/currentBackUrl",
        }),
    },
    methods: {
        ...mapActions({
            setCurrentBackUrl: "global/setCurrentBackUrl",
        }),
        getImagem(imagem, format = 'svg') {
            return require(`@/assets/images/${imagem}.${format}`);
        },
        showImage(relativePath) {
            // adiciona barra ao caminho caso nao haja
            relativePath = (relativePath.charAt(0) !== "/") ? `/${relativePath}` : relativePath;
            return process.env.VUE_APP_API_URL + relativePath;
        },        
        goToUrl(link) {
            if (window.scrollY > 0) {
                window.scrollTo(0, 0);
            }
            if (link !== this.$route.path) {
                this.$router.push(link).catch(() => { });
            }
        },
        backUrl() {
            if (this.currentBackUrl) {
                this.goToUrl(this.currentBackUrl);
                this.setCurrentBackUrl("");
            } else {
                this.$router.back();
            }
        },
        hasProp(obj, prop) {
            return Object.prototype.hasOwnProperty.call(obj, prop);
        },
        onDeviceReady() {
            this.deviceReady = true;
            console.log("DEVICE PRONTO");
        },
        versionTag(tagName) {
            return this.tag.indexOf(tagName) > -1;
        },        
    },
};
