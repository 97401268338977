import * as types from './types';

export const state = {
    perfil: {},
    artistas: [],
    artista: {},
    obrasPerfil: [],
    textosPerfil: [],
    exposicoesPerfil: [],
    eventosPerfil: [],
    currentScroll: 0,
    availableTabs: [],
    editorial: {}
}

export const mutations = {
    [types.SET_PERFIL](state, data) {
        state.perfil = data;
    },
    [types.GET_ARTISTAS](state, data) {
        state.artistas = data;
    },
    [types.GET_ARTISTA](state, data) {
        state.artista = data;
    },
    [types.GET_OBRAS_PERFIL](state, data) {
        state.obrasPerfil = data;
    },
    [types.GET_EVENTOS_PERFIL](state, data) {
        state.eventosPerfil = data;
    },
    [types.GET_EXPOSICOES_PERFIL](state, data) {
        state.exposicoesPerfil = data;
    },
    [types.GET_TEXTOS_PERFIL](state, data) {
        state.textosPerfil = data;
    },
    [types.SET_CURRENT_SCROLL](state, data) {
        state.currentScroll = data;
    },
    [types.SET_AVAILABLE_TABS](state, data) {
        state.availableTabs = data;
    },
    [types.SET_EDITORIAL](state, data) {
        state.editorial = data;
    }
};
