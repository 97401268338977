import * as types from './types';

export const state = {
    artistas: [],
    artista: {},
    obrasArtista: [],
    currentScroll: 0,
}

export const mutations = {
    [types.GET_ARTISTAS](state, data) {
        state.artistas = data;
    },
    [types.GET_ARTISTA](state, data) {
        state.artista = data;
    },
    [types.GET_OBRAS_ARTISTA](state, data) {
        state.obrasArtista = data;
    },
    [types.SET_CURRENT_SCROLL](state, data) {
        state.currentScroll = data;
    },
    [types.SET_ARTISTA](state, data) {
        state.artista = data;
    }
};
