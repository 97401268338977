export default [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "auth-login-view" */ './views/Login'),
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import(/* webpackChunkName: "auth-logout-view" */ './views/Logout'),
    },
    {
        path: '/refresh_session',
        name: 'RefreshSession',
        component: () => import(/* webpackChunkName: "auth-refresh-session-view" */ './views/RefreshSession'),
    },
    {
        path: '/esqueci_senha',
        name: 'EsqueciSenha',
        component: () => import(/* webpackChunkName: "auth-esqueci-senha-view" */ './views/EsqueciSenha'),
    },
    {
        path: '/confirmar_email',
        name: 'ConfirmarEmail',
        component: () => import(/* webpackChunkName: "auth-confirmar-email-view" */ './views/ConfirmarEmail'),
    },
    {
        path: '/criar_conta',
        name: 'CriarConta',
        component: () => import(/* webpackChunkName: "auth-criar-conta-view" */ './views/CriarConta'),
    },
    {
        path: '/editar_dados',
        name: 'EditarDados',
        component: () => import(/* webpackChunkName: "auth-editar-dados-view" */ './views/EditarDados'),
    },
    {
        path: '/criar_perfil_invite_code/:tipo_perfil',
        name: 'CriarPerfilInviteCode',
        component: () => import(/* webpackChunkName: "auth-criar-perfil-invite-code-view" */ './views/CriarPerfilInviteCode'),
    },
    {
        path: '/senha_solicitada',
        name: 'SenhaSolicitada',
        component: () => import(/* webpackChunkName: "auth-senha-solicitada-view" */ './views/SenhaSolicitada'),
    },
    {
        path: '/redefinir_senha',
        name: 'RedefinirSenha',
        component: () => import(/* webpackChunkName: "auth-esqueci-senha-view" */ './views/EsqueciSenha'),
    },
        {
        path: '/alterar_senha',
        name: 'AlterarSenha',
        component: () => import(/* webpackChunkName: "auth-alterar-senha-view" */ './views/AlterarSenha'),
    },
    {
        path: '/criar_perfil',
        name: 'CriarPerfil',
        component: () => import(/* webpackChunkName: "auth-criar-perfil-view" */ './views/CriarPerfil'),
    },
    {
        path: '/configuracoes',
        name: 'Configurações',
        component: () => import(/* webpackChunkName: "auth-configuracoes-view" */ './views/Configuracoes'),
    },
    {
        path: '/solicitacoes',
        name: 'Solicitações',
        component: () => import(/* webpackChunkName: "auth-solicitacoes-view" */ './views/Solicitacoes'),
    },
    {
        path: '/encerrar_conta',
        name: 'EncerrarConta',
        component: () => import(/* webpackChunkName: "auth-encerrar-conta-view" */ './views/EncerrarConta'),
    },
    {
        path: '/criar_conta_invite_code',
        name: 'CriarContaInviteCode',
        component: () => import(/* webpackChunkName: "auth-criar-conta-invite-code-view" */ './views/CriarContaInviteCode'),
    },
    {
        path: '/conta_criada',
        name: 'ContaCriada',
        component: () => import(/* webpackChunkName: "auth-conta-criada-view" */ './views/ContaCriada'),
    },
];

