import * as api from './base';

export const createExposicao = (params) => {
    const path = '/exposicoes';
    return api.postRequest(path, params);
};

export const uploadExposicao = (params, uuid) => {
    const path = '/exposicoes';
    return api.putRequest(path, params, uuid, api.configUpload);
};

export const updateExposicao = (params, uuid) => {
    const path = '/exposicoes';
    return api.putRequest(path, params, uuid);
};

export const deleteExposicao = (uuid) => {
    const path = '/exposicoes';
    return api.deleteRequest(path, uuid);
};

export const getExposicao = (uuid) => {
    const path = '/exposicoes/';
    return api.getRequest(path, uuid);
};

export const getExposicoes = (params) => {
    const path = '/exposicoes';
    return api.getRequest(path + api.parseQueryParams(params));
};

export const getParticipantes = (params) => {
    const path = '/users';
    return api.getRequest(path, api.parseQueryParams(params));
};
