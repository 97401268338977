import * as types from './types';

export const state = {
    cities: [],
    city: {},
    localities: [],
    locality: {},
    places: [],
};

export const mutations = {
    [types.SET_CITIES](state, cities) {
        state.cities = cities;
    },
    [types.SET_CITY](state, city) {
        state.city = city || {};
    },
    [types.SET_LOCALITIES](state, localities) {
        state.localities = localities;
    },
    [types.SET_LOCALITY](state, locality) {
        state.locality = locality || {};
    },
    [types.SET_PLACES](state, places) {
        state.places = places;
    },

};
