export const CREATE_CARRINHO = 'CREATE_CARRINHO';
export const GET_CARRINHO = 'GET_CARRINHO';
export const GET_CARRINHOS = 'GET_CARRINHOS';
export const UPDATE_CARRINHO = 'UPDATE_CARRINHO';
export const DELETE_CARRINHO = 'DELETE_CARRINHO';
export const CREATE_ENDERECO = 'CREATE_ENDERECO';
export const GET_ENDERECO = 'GET_ENDERECO';
export const GET_ENDERECOS = 'GET_ENDERECOS';
export const UPDATE_ENDERECO = 'UPDATE_ENDERECO';
export const DELETE_ENDERECO = 'DELETE_ENDERECO';
export const UPDATE_DADOS_PAGAMENTO = 'UPDATE_DADOS_PAGAMENTO';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_BRAND = 'SET_BRAND';
export const SET_BRAND_URL = 'SET_BRAND_URL';
export const SET_INSTALLMENTS = 'SET_INSTALLMENTS';
