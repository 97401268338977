import * as api from './base';

export const contactSeller = (params) => {
    const path = '/comms/send_message';
    return api.postRequest(path, params);
};

export const sendReport = (params) => {
    const path = '/comms/send_message';
    return api.postRequest(path, params);
};

export const getMessages = (params) => {
    const path = '/comms';
    return api.getRequest(path + api.parseQueryParams(params));
};
