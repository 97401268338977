import * as obraHelperAPI from '@/helpers/api/Obra';
import * as mediaHelperAPI from '@/helpers/api/Media';
import * as commsHelperAPI from '@/helpers/api/Comms';
import * as types from './types';

export const doUploadMediaTemp = async ({ commit, dispatch, state }, params) => {
    const result = await mediaHelperAPI.uploadMediaTemp(params)
          .then((response) => {
              if (response.data.status === 'false') {
                  if (typeof response.data.upload_limit !== "undefined") {
                      dispatch('global/setProgressBar', -1, { root: true });
                      dispatch('global/setProgressBarTotal', 0, { root: true });
                  }
                  dispatch(
                      `noticias/mensagemErro`,
                      response.data.mensagem,
                      { root: true },
                  );
              } else if (response.data.status === "true"
                         && typeof response.data.part !== "undefined"
                         && response.data.verified === "true"
                        ) {
                  // parte recebida - atualiza progress bar
                  if (state.interruptUpload) {
                      dispatch('global/setProgressBar', 0, { root: true });
                  }
                  dispatch('global/setProgressBar', 1, { root: true });
              } else {
                  // recebeu arquivo inteiro
                  if (state.interruptUpload) {
                      dispatch('global/setProgressBar', 0, { root: true });
                      dispatch('global/interruptUpload', false, { root: true });
                  } else {
                      dispatch('global/setProgressBar', 1, { root: true });
                      if (typeof response.data.updated_image_uuid !== "undefined") {
                          commit(types.SET_UPDATED_UUID, response.data.updated_image_uuid);
                      }
                      commit(types.SET_UPDATE_IMAGE, "true");
                      commit(types.UPDATE_MEDIA_TEMP, response.data);
                  }
              }
              return response.data;
          }).catch((e) => {
              console.log(e);
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};

export const interruptUpload = async ({ commit}, params) => {
    commit(types.INTERRUPT_UPLOAD, params.interrupt);
    const result = await mediaHelperAPI.interruptUpload(params)
          .then((response) => {
              return response.data;
          });
    return result;
};

export const setUpdatedUUID = ({ commit}, data) => {
    commit(types.SET_UPDATED_UUID, data);
};

export const setUpdateImage = ({ commit}, data) => {
    commit(types.SET_UPDATE_IMAGE, data);
};

export const setCurrentIndex = ({ commit}, data) => {
    commit(types.SET_CURRENT_INDEX, data);
};

export const setImages = ({ commit}, data) => {
    commit(types.SET_IMAGES, data);
};

export const doUpdateMediaTempList = ({ commit }, params) => {
    commit(types.UPDATE_MEDIA_TEMP, params);
};

export const doRemoveMediaTemp = ({ commit }, params) => {
    mediaHelperAPI.deleteMediaTemp(params)
        .then(() => {
            if (Object.prototype.hasOwnProperty.call(params, 'username')) {
                commit(types.CLEAR_MEDIA_TEMP);
            } else {
                commit(types.REMOVE_MEDIA_TEMP, params);
            }
        });
};


export const doCreateObra = async ({ commit, dispatch }, params) => {
    const result = await obraHelperAPI.createObra(params)
        .then((response) => {
            commit(types.CREATE_OBRA, response.data.obra);
            if (response.data.status === 'false') {
                dispatch(
                    `noticias/mensagemErro`,
                    response.data.mensagem,
                    { root: true },
                );
            }
            return response.data;
        });
    return result;
};

export const doUpdateObra = async ({ commit, dispatch }, params) => {
    const result = await obraHelperAPI.updateObra(params, params.uuid)
          .then((response) => {
              if (response.data.status === 'false') {
                dispatch(
                    `noticias/mensagemErro`,
                    response.data.mensagem,
                    { root: true },
                );
              }
              if (response.data.status === "true") {
                  commit(types.UPDATE_OBRA, response.data.obra);
              }
              return response.data.obra;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};

export const doUpdateObraFichaTecnica = async ({ commit, dispatch }, params) => {
    const result = await obraHelperAPI.updateObraFichaTecnica(params, params.uuid)
        .then((response) => {
            if (response.data.status === 'false') {
                dispatch(
                    `noticias/mensagemErro`,
                    response.data.mensagem,
                    { root: true },
                );
            }
            if (response.data.status === "true") {
                commit(types.UPDATE_OBRA_FICHA_TECNICA, response.data.ficha_tecnica);
            }
            return response.data.ficha_tecnica;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
    return result;
};

export const cleanObra = ({ commit }) => {
    commit(types.CLEAN_OBRA);
    return true;
};

export const getObra = async ({ commit, dispatch }, params) => {
    const result = await obraHelperAPI.getObra(params.uuid)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_OBRA, response.data.obra);
            } else {
                return false;
            }
            return response.data;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getObraFichaTecnica = async ({ commit, dispatch }, params) => {
    const result = await obraHelperAPI.getObraFichaTecnica(params.uuid)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_OBRA_FICHA_TECNICA, response.data.ficha_tecnica);
            } else {
                commit(types.GET_OBRA_FICHA_TECNICA, {});
            }
            return response.data;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getObras = async ({ commit, dispatch }, params) => {
    const result = await obraHelperAPI.getObras(params)
        .then((response) => {
            if (response.data.status === "true") {
                if (Object.prototype.hasOwnProperty.call(response.data, 'total')) {
                    commit(types.SET_TOTAL_OBRAS, response.data.total);
                }
                commit(types.GET_OBRAS, response.data.obras);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const setLocalObras = ({ commit }, params) => {
    commit(types.SET_LOCAL_OBRAS, params);
};

export const updateItemLocalObras = ({ commit }, params) => {
    commit(types.UPDATE_ITEM_LOCAL_OBRAS, params);
};

export const setCurrentScroll = ({ commit }, params) => {
    commit(types.SET_CURRENT_SCROLL, params);
};

export const setCurrentSearch = ({ commit }, params) => {
    commit(types.SET_CURRENT_SEARCH, params);
};

export const deleteObra = async ({ commit, dispatch }, params) => {
    const result = await obraHelperAPI.deleteObra(params)
          .then((response) => {
              if (response.data.status === 'true') {
                  commit(types.DELETE_OBRA);
                  return true;
              }
              return false;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const contactSaler = async ({ dispatch } , params) => {
    const result = await commsHelperAPI.contactSeller(params)
          .then((response) => {
              return response.data;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const sendReport = async ({ dispatch } , params) => {
    const result = await commsHelperAPI.sendReport(params)
          .then((response) => {
              return response.data;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const getLinguagensFilled = ({ commit, dispatch }) => {
    const result = obraHelperAPI.getLinguagensFilled()
          .then((response) => {
              commit(types.SET_LINGUAGENS_FILLED, response.data);
              return response.data;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const doUpdateWeight = ({ dispatch }, params) => {
    const result = obraHelperAPI.updateWeight(params, params.uuid)
          .then(() => {
              return true;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const setEnableChangeOrder = ({ commit }, data) => {
    commit(types.SET_ENABLE_CHANGE_ORDER, data);
};
