export const CREATE_OBRA = 'CREATE_OBRA';
export const GET_OBRA = 'GET_OBRA';
export const CLEAN_OBRA = 'CLEAN_OBRA';
export const GET_OBRA_FICHA_TECNICA = 'GET_OBRA_FICHA_TECNICA';
export const GET_OBRAS = 'GET_OBRAS';
export const UPDATE_OBRA = 'UPDATE_OBRA';
export const DELETE_OBRA = 'DELETE_OBRA';
export const UPDATE_OBRA_FICHA_TECNICA = 'UPDATE_OBRA_FICHA_TECNICA';
export const UPDATE_MEDIA_TEMP = 'UPDATE_MEDIA_TEMP';
export const CLEAR_MEDIA_TEMP = 'CLEAR_MEDIA_TEMP';
export const REMOVE_MEDIA_TEMP = 'REMOVE_MEDIA_TEMP';
export const SET_UPDATED_UUID = 'SET_UPDATED_UUID';
export const SET_UPDATE_IMAGE = 'SET_UPDATE_IMAGE';
export const SET_CURRENT_INDEX = 'SET_CURRENT_INDEX';
export const SET_IMAGES = 'SET_IMAGES';
export const INTERRUPT_UPLOAD = 'INTERRUPT_UPLOAD';
export const SET_LINGUAGENS_FILLED = 'SET_LINGUAGENS_FILLED';
export const SET_LOCAL_OBRAS = 'SET_LOCAL_OBRAS';
export const UPDATE_ITEM_LOCAL_OBRAS = 'UPDATE_ITEM_LOCAL_OBRAS';
export const SET_CURRENT_SCROLL = 'SET_CURRENT_SCROLL';
export const SET_CURRENT_SEARCH = 'SET_CURRENT_SEARCH';
export const SET_TOTAL_OBRAS = 'SET_TOTAL_OBRAS';
export const SET_ENABLE_CHANGE_ORDER = 'SET_ENABLE_CHANGE_ORDER';
