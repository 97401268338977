import Vue from 'vue';
import Vuex from 'vuex';
import artista from './modules/artista/store';
import auth from './modules/auth/store';
import evento from './modules/evento/store';
import exposicao from './modules/exposicao/store';
import obra from './modules/obra/store';
import texto from './modules/texto/store';
import noticias from './modules/noticias/store';
import loja from './modules/loja/store';
import admin from './modules/admin/store';
import localizacao from './modules/localizacao/store';
import simple_masonry from './modules/simple_masonry/store';
import global from './modules/store';
import favorito from './modules/favorito/store';
import perfis from './modules/perfis/store';
import parceiro from './modules/parceiro/store';

const debug = process.env.NODE_ENV !== 'production' || process.env.NODE_ENV !== 'staging';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        artista,
        auth,
        evento,
        exposicao,
        obra,
        noticias,
        loja,
        admin,
        simple_masonry,
        global,
        texto,
        localizacao,
        favorito,
        perfis,
        parceiro,
    },
    getters: {
        route: state => state.route,
    },
    strict: debug,
});

