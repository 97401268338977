export const perfil = state => state.perfil;
export const artistas = state => state.artistas;
export const artista = state => state.artista;
export const obrasPerfil = state => state.obrasPerfil;
export const eventosPerfil = state => state.eventosPerfil;
export const exposicoesPerfil = state => state.exposicoesPerfil;
export const textoPerfil = state => state.textosPerfil;
export const currentScroll = state => state.currentScroll;
export const availableTabs = state => state.availableTabs;
export const editorial = state => state.editorial;
