export default [
    {
        path: '/artistas/:id/editar',
        name: 'Editar Perfil de artista',
        component: () => import(/* webpackChunkName: "artista-editar-perfil-view" */ './views/EditarPerfil'),
    },
    {
        path: '/artistas/:id',
        name: 'Perfil do artista',
        component: () => import(/* webpackChunkName: "artista-perfil-view" */ './views/Perfil'),
    },
    {
        path: '/artistas/:id/:selected_tab_name',
        name: 'Perfil do artista',
        component: () => import(/* webpackChunkName: "artista-perfil-view" */ './views/Perfil'),
    },
];
