import * as types from "./types";
import * as commsHelperAPI from '@/helpers/api/Comms';
import * as configuracaoHelperAPI from '@/helpers/api/Configuracao';
import * as mediaHelperAPI from '@/helpers/api/Media';

export const setMenu = ({ commit }, data) => {
    commit(types.SET_MENU, data);
};

export const setIsMobile = ({ commit }, data) => {
    commit(types.SET_IS_MOBILE, data);
};

export const setWindowWidth = ({ commit }, data) => {
    commit(types.SET_WINDOW_WIDTH, data);
};

export const setWindowHeight = ({ commit }, data) => {
    commit(types.SET_WINDOW_HEIGHT, data);
};

export const getMessages = ({ commit, dispatch }, params) => {
    const result = commsHelperAPI.getMessages(params)
            .then((response) => {
            if (response.data.status === "true") {
                commit(types.SET_MESSAGES, response.data.messages);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const setCurrentBackUrl = ({ commit }, params) => {
    commit(types.SET_CURRENT_BACK_URL, params);
};

export const setZoomScale = ({ commit }, data) => {
    commit(types.ZOOM_SCALE, data);
};

export const setZoomSettings = ({ commit }, data) => {
    commit(types.ZOOM_SETTINGS, data);
};

export const setContentSizeStyle = ({ commit }, params) => {
    commit(types.SET_CONTENT_SIZE_STYLE, params);
};

export const setDebugMessages = ({ commit }, params) => {
    commit(types.SET_DEBUG_MESSAGES, params);
};

export const setCloseKeyboard = ({ commit }, params) => {
    commit(types.SET_CLOSE_KEYBOARD, params);
};

export const setCondensedView = ({ commit }, params) => {
    commit(types.ACTIVATE_CONDENSED_VIEW, params);
};

export const setIsExibeCarrinho = async ({ commit, dispatch }) => {
    const result = configuracaoHelperAPI.getExibeCarrinho()
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.SET_IS_EXIBE_CARRINHO, response.data.exibe_carrinho.valor);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });

    return result;
};


export const setProgressBarTotal = ({ commit}, data) => {
    commit(types.PROGRESS_BAR_TOTAL, data);
};

export const setProgressBar = ({ commit}, data) => {
    commit(types.PROGRESS_BAR, data);
};

export const doUpdateMediaTempList = ({ commit }, params) => {
    commit(types.UPDATE_MEDIA_TEMP, params);
};

export const doRemoveMediaTemp = ({ commit }, params) => {
    mediaHelperAPI.deleteMediaTemp(params)
        .then(() => {
            if (Object.prototype.hasOwnProperty.call(params, 'username')) {
                commit(types.CLEAR_MEDIA_TEMP);
            } else {
                commit(types.REMOVE_MEDIA_TEMP, params);
            }
        });
};

export const interruptUpload = async ({ commit}, params) => {
    commit(types.INTERRUPT_UPLOAD, params.interrupt);
    const result = await mediaHelperAPI.interruptUpload(params)
          .then((response) => {
              return response.data;
          });
    return result;
};

export const doUploadMediaTemp = async ({ commit, dispatch, state }, params) => {
    const result = await mediaHelperAPI.uploadMediaTemp(params)
          .then((response) => {
              if (response.data.status === 'false') {
                  if (typeof response.data.upload_limit !== "undefined") {
                      dispatch('global/setProgressBar', -1, { root: true});
                      dispatch('global/setProgressBar_TOTAL', 0, { root: true});
                  }
                  dispatch(
                      `noticias/mensagemErro`,
                      response.data.mensagem,
                      { root: true },
                  );
              } else if (response.data.status === "true"
                         && typeof response.data.part !== "undefined"
                         && response.data.verified === "true"
                        ) {
                  // parte recebida - atualiza progress bar
                  if (state.interruptUpload) {
                      dispatch('global/setProgressBar', 0, { root: true});
                  }
                  dispatch('global/setProgressBar', 1, { root: true});
              } else {
                  // recebeu arquivo inteiro
                  if (state.interruptUpload) {
                      dispatch('global/setProgressBar', 0, { root: true});
                      commit(types.INTERRUPT_UPLOAD, false);
                  } else {
                      dispatch('global/setProgressBar', 1, { root: true});
                      commit(types.UPDATE_MEDIA_TEMP, response.data);
                      commit(types.SET_IMAGE_TYPE_SELECTED, response.data.media['reference']['subreference'])
                  }
              }
              return response.data;
          }).catch((e) => {
              console.log(e);
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
        });
    return result;
};
