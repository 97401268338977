export const SET_MENU = 'SET_MENU';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_CONNECTED = 'SET_CONNECTED';
export const SET_CURRENT_BACK_URL = 'SET_CURRENT_BACK_URL';
export const ZOOM_SCALE = 'ZOOM_SCALE';
export const ZOOM_SETTINGS = 'ZOOM_SETTINGS';
export const SET_CONTENT_SIZE_STYLE = 'SET_CONTENT_SIZE_STYLE';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';
export const SET_WINDOW_HEIGHT = 'SET_WINDOW_HEIGHT';
export const SET_DEBUG_MESSAGES = 'SET_DEBUG_MESSAGES';
export const SET_CLOSE_KEYBOARD = 'SET_CLOSE_KEYBOARD';
export const ACTIVATE_CONDENSED_VIEW = 'ACTIVATE_CONDENSED_VIEW';
export const SET_IS_EXIBE_CARRINHO = 'SET_IS_EXIBE_CARRINHO';
export const PROGRESS_BAR = 'PROGRESS_BAR';
export const PROGRESS_BAR_TOTAL = 'PROGRESS_BAR_TOTAL';
export const UPDATE_MEDIA_TEMP = 'UPDATE_MEDIA_TEMP';
export const CLEAR_MEDIA_TEMP = 'CLEAR_MEDIA_TEMP';
export const REMOVE_MEDIA_TEMP = 'REMOVE_MEDIA_TEMP';
export const INTERRUPT_UPLOAD = 'INTERRUPT_UPLOAD';
export const SET_IMAGE_TYPE_SELECTED = 'SET_IMAGE_TYPE_SELECTED';
