import * as types from "./types";

export const state = {
    texto: {},
    textos: [],
    interruptUpload: false,
}

export const mutations = {
    [types.CREATE_TEXTO](state, data) {
        state.texto = data;
    },
    [types.GET_TEXTO](state, data) {
        state.texto = data;
    },
    [types.UPDATE_TEXTO](state, data) {
        state.texto = data;
    },
    [types.DELETE_TEXTO](state) {
        state.texto = "";
    },
    [types.GET_TEXTOS](state, data) {
        state.textos = data;
    },
}
