import axios from 'axios';

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    const conf = config;
    if (token) {
        conf.headers.Authorization = `Bearer ${token}`;
    }
    return conf;
}, err => Promise.reject(err));

let instance = {};
if (process.env.NODE_ENV !== 'production') {
    const API_ENDPOINT = '';
    instance = axios.create({
        baseURL: API_ENDPOINT,
    });
} else {
    instance = axios.create({
        baseURL: '',
    });
}
export { instance as axios_instance };

export const parseQueryParams = (params) => {
    let queryParams = '';
    Object.keys(params).forEach((key) => {
        if (params[key] !== null) {
            queryParams += (queryParams === '') ? '?' : '&';
            queryParams += `${key}=${params[key]}`;
        }
    });
    return queryParams;
};

export const config = {
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
};

export const configUpload = {
    headers: {
        'Accept': '',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
    },
};

export const basePath = process.env.VUE_APP_API_URL;

export const getRequest = (path, queryParams = '') => axios.get(`${basePath}${path}${queryParams}`, config);

export const postRequest = (path, data) => axios.post(`${basePath}${path}`, data, config);

export const putRequest = (path, bodyFormData, id, config) => axios.put(`${basePath}${path}/${id}`, bodyFormData, config).catch(error => {
    console.log(error.response)
});

export const simplePutRequest = (path, bodyFormData, id, config) => axios.put(`${basePath}${path}`, bodyFormData, config).catch(error => {
    console.log(error.response)
});

export const deleteRequest = (path, id) => axios.delete(`${basePath}${path}/${id}`, config);

export const queryDeleteRequest = (path, queryParams) => axios.delete(`${basePath}${path}${queryParams}`, config);

export const optionsRequest = path => axios.options(`${basePath}${path}`, config);

export const outsideGetRequest = (apiAddress, path, queryParams = '') => axios.get(`${apiAddress}${path}${queryParams}`, config);

export const externalPost = async (path) => {
    const result = await axios.post(path, config);
    return result;
}
