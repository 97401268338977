import * as authHelperAPI from '@/helpers/api/Auth';
import * as mediaHelperAPI from '@/helpers/api/Media';
import * as types from './types';

export const doLogin = ({ commit, dispatch }, params) => {
    return authHelperAPI.login(params)
        .then((response) => {
            if (response.data.status === "true") {
                //axios.defaults.headers.common['Authorization'] = token
                commit(types.REGISTER_LOGIN, response.data);
                if ('filter_configurations' in response.data.usuario) {
                    commit(types.UPDATE_FILTER_CONFIGURATIONS, response.data.usuario);
                }
                const temporary = Object.prototype.hasOwnProperty.call(response.data.usuario, 'temporary');
                commit(types.SET_IS_TEMPORARY_PASSWORD, Boolean(temporary));
            } else {
                dispatch(
                    'noticias/mensagemErro',
                    'Erro ao tentar efetuar login!',
                    { root: true },
                );
                localStorage.removeItem('token');
            }
        });
};

export const setIsTemporaryPassword = ({ commit }, params) => {
    commit(types.SET_IS_TEMPORARY_PASSWORD, params);
};

export const doLogout = ({ commit }) => {
    return new Promise((resolve) => {
        authHelperAPI.logout();
        commit(types.LOGOUT);
        //delete axios.defaults.headers.common['Authorization']
        resolve();
    })
};

export const doRefreshSession = ({ commit }, params) => {
    return authHelperAPI.refreshSession(params)
        .then(response => {
                commit(types.REGISTER_LOGIN, response.data);
                if ('filter_configurations' in response.data.usuario) {
                    commit(types.UPDATE_FILTER_CONFIGURATIONS, response.data.usuario);
                }
                const temporary = Object.prototype.hasOwnProperty.call(response.data.usuario, 'temporary');
                commit(types.SET_IS_TEMPORARY_PASSWORD, Boolean(temporary));
        });
};

export const doCreateUser = ({ commit, dispatch }, params) => {
    dispatch(
        'noticias/mensagemSucesso',
        'Criando usuário, aguarde...',
        { root: true },
    );
    const response =  authHelperAPI.createUser(params)
          .then((response) => {
              if (response.data.status === 'true') {
                  commit(types.CREATE_USER);
              }
              return response;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
            );
          });
    return response;
};

export const doResetPassword = async ({ commit, dispatch }, params) => {
    const response = authHelperAPI.resetPassword(params)
        .then((response) => {
            commit(types.RESET_PASSWORD);
            if (response.data.status === "true") {
                dispatch(
                    'noticias/mensagemSucesso',
                    response.data.mensagem,
                    { root: true },
                );
            } else {
                dispatch(
                    'noticias/mensagemErro',
                    response.data.mensagem,
                    { root: true },
                );
            }
            return response.data;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return response;
};

export const doRemoveAccount = ({ dispatch }, params) => {
    const response = authHelperAPI.removeAccount(params)
          .then((response) => {
              if (response.data.status === "true") {
                  dispatch(
                      'noticias/mensagemSucesso',
                      response.data.mensagem,
                      { root: true },
                  );
                  dispatch("doLogout");
                  return true;
              } else {
                  dispatch(
                      'noticias/mensagemErro',
                      response.data.mensagem,
                      { root: true },
                  );
                  return false;
              }
          });
    return response;
};

export const checkIsValidResetPassword = ({ commit }, params) => {
    authHelperAPI.isValidResetPassword(params)
        .then((response) => {
            commit(types.VALID_RESET_PASSWORD, response.data);
        });
};

export const checkEmailAvailable = async ({ commit }, params) => {
    const response = await authHelperAPI.getEmailAvailable(params)
        .then((response) => {
            commit(types.EMAIL_AVAILABLE, response.data);
            return response.data;
        });
    return response;
};

export const checkUsernameAvailable = async ({ commit }, params) => {
    const response = await authHelperAPI.getUsernameAvailable(params)
        .then((response) => {
            commit(types.USERNAME_AVAILABLE, response.data);
            return response.data;
        });
    return response;
};

export const doUpdatePassword = ({ commit, dispatch }, params) => {
    authHelperAPI.updateUser(params)
        .then((response) => {
            commit(types.UPDATE_USER);
            dispatch(
                'noticias/mensagemSucesso',
                response.data.mensagem,
                { root: true },
            );
        });
};

export const confirmEmail = ({ commit, dispatch }, params) => {
    authHelperAPI.confirmEmail(params)
        .then((response) => {
            let tipoMensagem = (response.data.status === 'true') ? 'mensagemSucesso' : 'mensagemErro';
            dispatch(
                `noticias/${tipoMensagem}`,
                response.data.mensagem,
                { root: true },
            );
            if (response.data.status === "true") {
                commit(types.EMAIL_CONFIRMED, true);
            } else {
                commit(types.EMAIL_CONFIRMED, false);
            }
        });
};

export const getUser = ({ commit, dispatch }, params) => {
    authHelperAPI.getUser(params)
        .then((response) => {
            let tipoMensagem = (response.data.status === 'true') ? 'mensagemSucesso' : 'mensagemErro';
            dispatch(
                `noticias/${tipoMensagem}`,
                response.data.mensagem,
                { root: true },
            );
            if (response.data.status === "true") {
                commit(types.SET_USER, response.data);
            }
        });
};

export const clearFilterConfigurations = async ({ commit }) => {
    commit(types.CLEAR_FILTER_CONFIGURATIONS, {});
};

export const getFilterConfigurations = ({ commit }, params) => {
    commit(types.UPDATE_FILTER_CONFIGURATIONS, params);
};

export const updateFilterConfigurations = async ({ commit }, params) => {
    commit(types.UPDATE_FILTER_CONFIGURATIONS, params);
};

export const setFilterConfiguration = async ({ commit, getters }, { filtro, name }) => {
    var filterConfigurations = JSON.parse(JSON.stringify(getters.filterConfigurations))
    //debugger
    if (!filterConfigurations[filtro]) {
        filterConfigurations[filtro] = {
            active: true,
            fields: {
                [name]: true
            }
        }
    } else {
        filterConfigurations[filtro].fields[name] = !filterConfigurations[filtro].fields[name]
        if (!filterConfigurations[filtro].fields[name]) {
            delete filterConfigurations[filtro].fields[name];
        }
        /// dando pau na hora de desligar o filtro
        filterConfigurations[filtro].active = ! filterConfigurations[filtro].active;
    }
    if (filterConfigurations[filtro].active === false) {
        if ('sort' in filterConfigurations[filtro].fields) {
            delete filterConfigurations[filtro].fields['sort'];
        }
    }
    const data = {
        filter_configurations: filterConfigurations,
        section: filtro,
    };
    commit(types.UPDATE_FILTER_CONFIGURATIONS, data);
};

export const doUpdateUser = async ({ commit, dispatch }, params) => {
    let username = (typeof params.username !== 'undefined') ? params.username : params.get('username');
    const result = await authHelperAPI.updateUser(params, username)
          .then((response) => {
              if (response.data.status === "true") {
                  commit(types.UPDATE_USER, response.data.usuario);
                  const loggedUser = JSON.parse(localStorage.getItem('usuario')).username;
                  if (loggedUser === response.data.usuario.username) {
                      localStorage.setItem('usuario', JSON.stringify(response.data.usuario));
                  }
                return JSON.stringify(response.data.usuario);
              } else {
                  return response.data;
              }
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
    return result;
};

export const doUploadAvatar = async ({ commit, dispatch }, params) => {
    let username = (typeof params.username !== 'undefined') ? params.username : params.get('username');
    const result = await authHelperAPI.uploadAvatar(params, username)
        .then((response) => {
              if (response.data.status === 'false') {
                  if (typeof response.data.upload_limit !== "undefined") {
                      dispatch('global/setProgressBar', -1, { root: true });
                      dispatch('global/setProgressBarTotal', 0, { root: true });
                  }
                  dispatch(
                      `noticias/mensagemErro`,
                      response.data.mensagem,
                      { root: true },
                  );
              } else if (response.data.status === "true"
                         && typeof response.data.part !== "undefined"
                         && response.data.verified === "true"
                        ) {
                  // parte recebida - atualiza progress bar
                  dispatch('global/setProgressBar', 1, { root: true });
              } else {
                  // recebeu arquivo inteiro
                  dispatch('global/setProgressBar', 1, { root: true });
                  commit(types.UPDATE_MEDIA_TEMP, response.data.media);
              }
            return response.data;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
    return result;
};

export const doRemoveMediaTemp = ({ commit }, params) => {
    mediaHelperAPI.deleteMediaTemp(params)
        .then(() => {
            commit(types.UPDATE_MEDIA_TEMP, '');
       });
};

export const setImages = ({ commit}, data) => {
    commit(types.SET_IMAGES, data);
};

export const doCreateDeviceUuid = ({ commit, dispatch }, params) => {
    const response =  authHelperAPI.createDeviceUuid(params)
          .then((response) => {
              if (response.data.status === 'true') {
                  commit(types.SET_DEVICE_UUID, response.data);
              }
              return response;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
            );
          });
    return response;
};

export const doUpdateDeviceUuid = ({ commit, dispatch }, params) => {
    const response = authHelperAPI.updateDeviceUuid(params, params.uuid)
          .then((response) => {
              if (response.data.status === 'true') {
                  commit(types.SET_DEVICE_UUID, response.data);
              }
              return response;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return response;
};

export const doGetDeviceUuid = ({ commit, dispatch }, uuid) => {
    const response = authHelperAPI.getDeviceUuid(uuid)
          .then((response) => {
              if (response.data.status === 'true') {
                  commit(types.SET_DEVICE_UUID, response.data);
              }
              return response;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
            );
          });
    return response;
};

export const doSetShowDebug = ({ commit }, data) => {
    commit(types.SET_SHOW_DEBUG, data);   
};

export const cleanCreateUserInviteCode = ({ commit }) => {
    commit(types.SET_CREATE_USER_INVITE_CODE, "");
};

export const doCreateUserInviteCode = ({ commit, dispatch }, params) => {
    const response =  authHelperAPI.createUserInviteCode(params)
          .then((response) => {
              if (response.data.status === 'true') {
                  commit(types.SET_CREATE_USER_INVITE_CODE, response.data.invite_code);
              } else {
                  dispatch(
                      'noticias/mensagemErro',
                      response.data.mensagem,
                      { root: true },
                  );
              }
              return response;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
            );
          });
    return response;
};

export const setExibeExpiracao = ({ commit }, params) => {
    commit(types.SET_EXIBE_EXPIRACAO, params);
};

export const updateMediaTemp = ({ commit }, params) => {
    commit(types.UPDATE_MEDIA_TEMP, params);
};
