import store from '@/store';

export default {
    methods: {
        hasConnection() {
            // mobile
            var connection = navigator.onLine;
            if (typeof navigator.connection !== "undefined") {
                if (typeof navigator.connection.type !== "undefined") {
                    return navigator.connection.type !== 'Connection.NONE';
                }
            }
            if (connection) {
                this.isConnected = true;
            }
            // web
            return connection;
        },
        updateConnectionStatus($event) {
            if ($event.type === 'online') {
                this.isConnected = true;
            }
            if ($event.type === 'offline') {
                this.isConnected = false;
            }
            store.commit('global/SET_CONNECTED', this.isConnected);
        },
    },
    data() {
        return {
            isConnected: this.hasConnection(),
        }
    },
};
