import * as api from './base';

export const createEvento = (params) => {
    const path = '/eventos';
    return api.postRequest(path, params, api.configUpload);
};

export const uploadEvento = (params, uuid) => {
    const path = '/eventos';
    return api.putRequest(path, params, uuid, api.configUpload);
};

export const updateEvento = (params, uuid) => {
    const path = '/eventos';
    return api.putRequest(path, params, uuid);
};

export const deleteEvento = (uuid) => {
    const path = '/eventos';
    return api.deleteRequest(path, uuid);
};

export const getEvento = (uuid) => {
    const path = '/eventos/';
    return api.getRequest(path, uuid);
};

export const getEventos = (params) => {
    const path = '/eventos';
    return api.getRequest(path + api.parseQueryParams(params));
};

export const getEventosOcorrencia = (params) => {
    const path = '/eventos/ocorrencia';
    return api.getRequest(path + api.parseQueryParams(params));
};

export const getParticipantes = (params) => {
    const path = '/users';
    return api.getRequest(path, api.parseQueryParams(params));
};
