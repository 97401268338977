import * as api from './base';

export const getExibeCarrinho = () => {
    const path = '/configuracao/get_exibe_carrinho';
    return api.getRequest(path);
};
export const getAvailableContent = (profile, username, is_edit) => {
    const path = `/configuracao/available_content/${profile}/${username}/${is_edit}`;
    return api.getRequest(path);
};
