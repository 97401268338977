export default [
    {
        path: '/textos/novo',
        name: 'Novo texto',
        component: () => import(/* webpackChunkName: "texto-novo-view" */ './views/TextoNovo'),
    },
    {
        path: '/textos/novo/:username',
        name: 'Novo texto',
        component: () => import(/* webpackChunkName: "texto-novo-view" */ './views/TextoNovo'),
    },
    {
        path: '/textos/:uuid/editar',
        name: 'Editar texto',
        component: () => import(/* webpackChunkName: "texto-novo-view" */ './views/TextoEditar'),
    },
];
