import Vue from "vue";
import * as types from "./types";

export const state = {
    obra: "",
    obraFichaTecnica: {},
    obras: [],
    participantes: [],
    mediaTemp: [],
    sugestaoEndereco: "",
    updatedUUID: "",
    updateImage: "false",
    currentIndex: 0,
    images: [],
    interruptUpload: false,
    linguagensFilled: [],
    localObras: [],
    currentScroll: 0,
    currentSearch: "",
    total: 0,
    enableChangeOrder: true,
}

export const mutations = {
    [types.CREATE_OBRA](state, data) {
        state.obra = data;
    },
    [types.GET_OBRA](state, data) {
        state.obra = data;
    },
    [types.CLEAN_OBRA](state) {
        state.obra = "";
    },
    [types.GET_OBRAS](state, data) {
        state.obras = data;
    },
    [types.GET_OBRA_FICHA_TECNICA](state, data) {
        Vue.set(state.obraFichaTecnica, data.uuid, data);
    },
    [types.SET_LOCAL_OBRAS](state, data) {
        state.localObras = data;
    },
    [types.UPDATE_ITEM_LOCAL_OBRAS](state, data) {
        const idxLocalObra = state.localObras.findIndex(item => item.uuid === data.uuid);
        state.localObras[idxLocalObra] = data
    },
    [types.SET_CURRENT_SCROLL](state, data) {
        state.currentScroll = data;
    },
    [types.SET_CURRENT_SEARCH](state, data) {
        state.currentSearch = data;
    },
    [types.UPDATE_OBRA](state, data) {
        state.obra = data;
    },
    [types.UPDATE_OBRA_FICHA_TECNICA](state, data) {
        Vue.set(state.obraFichaTecnica, data.uuid, data);
    },
    [types.UPDATE_MEDIA_TEMP](state, data) {
        if (Object.prototype.hasOwnProperty.call(data, "updated_image_uuid")) {
            if (state.currentIndex > -1 && state.images.length > 0) {
                Object.assign(state.images[state.currentIndex], data.media);
            } else if (state.images.length === 0) {
                Object.assign(state.images.push(data.media));
            }
        } else {
            if (typeof data.media !== "undefined") {
                if (state.images.length > data.media.length) {
                    state.images = data.media;
                } else {
                    state.images.push(data.media);
                }
                if (state.mediaTemp.length > data.media.length) {
                    state.mediaTemp = data.media;
                } else {
                    state.mediaTemp.push(data.media);
                }
            }
            state.currentIndex = state.images.length -1;
        }
    },
    [types.SET_UPDATED_UUID](state, data) {
        state.updatedUUID = data;
    },
    [types.SET_UPDATE_IMAGE](state, data) {
        state.updateImage = data;
    },
    [types.DELETE_OBRA](state) {
        state.obra = "";
        state.participantes = [];
    },
    [types.CLEAR_MEDIA_TEMP](state) {
        for (let i = 0; i < state.mediaTemp.length; i++) {
            let media = state.mediaTemp[i];
            let indexImages = state.images.findIndex( o => o.uuid == media.uuid);
            state.images.splice(indexImages, 1);
        }
        state.mediaTemp = [];
    },
    [types.REMOVE_MEDIA_TEMP](state, params) {
        const removedUUID = params.uuid;
        let index = state.mediaTemp.findIndex( o => o.uuid == removedUUID );
        state.mediaTemp.splice(index, 1);
    },
    [types.SET_CURRENT_INDEX](state, data) {
        state.currentIndex = data;
    },
    [types.SET_IMAGES](state, data) {
        state.images = data;
    },
    [types.INTERRUPT_UPLOAD](state, data) {
        state.interruptUpload = data;
    },
    [types.SET_LINGUAGENS_FILLED](state, data) {
        state.linguagensFilled = data.linguagens;
    },
    [types.SET_TOTAL_OBRAS](state, data) {
        state.total = data;
    },
    [types.SET_ENABLE_CHANGE_ORDER](state, data) {
        state.enableChangeOrder = data;
    },
};
