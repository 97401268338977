import * as types from './types';
import * as LocalizacaoHelperAPI from '@/helpers/api/Localizacao';

export const getCities = ({ commit, dispatch }, city) => {
    const result =  LocalizacaoHelperAPI.getCities(city)
          .then((response) => {
              commit(types.SET_CITIES, response.data.cities);
              return response.data;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const getCitiesNotAggregated = ({ commit, dispatch }, city) => {
    const result = LocalizacaoHelperAPI.getCitiesNotAggregated(city)
          .then((response) => {
              commit(types.SET_CITIES, response.data.cities);
              return response.data;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const getCityByCode = ({ commit, dispatch }, city_code) => {
    const result = LocalizacaoHelperAPI.getCityByCode(city_code)
          .then((response) => {
              commit(types.SET_CITY, response.data.city);
              return response.data.city;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const getLocalitiesByCity = ({ commit, dispatch }, params) => {
    const result = LocalizacaoHelperAPI.getLocalitiesByCity(params)
          .then((response) => {
              commit(types.SET_LOCALITIES, response.data.localities);
              return response.data;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const getLocalityByCep = ({ commit, dispatch }, cep) => {
    const result = LocalizacaoHelperAPI.getLocalityByCep(cep)
          .then((response) => {
              commit(types.SET_LOCALITY, response.data.locality);
              return response.data.locality;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const getLocalityByObjectId = ({ commit, dispatch }, cep) => {
    const result = LocalizacaoHelperAPI.getLocalityByObjectId(cep)
          .then((response) => {
              commit(types.SET_LOCALITY, response.data.locality);
              return response.data.locality;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const doGetPlaces = ({ commit, dispatch }, params) => {
    const result = LocalizacaoHelperAPI.getPlaces(params)
        .then((response) => {
          commit(types.SET_PLACES, response.data);
        })
        .catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
           );
           return false;
        })
    return result;
};
