import * as artistaHelperAPI from '@/helpers/api/Artista';
import * as obraHelperAPI from '@/helpers/api/Obra';
import * as eventoHelperAPI from '@/helpers/api/Evento';
import * as exposicaoHelperAPI from '@/helpers/api/Exposicao';
import * as textoHelperAPI from '@/helpers/api/Texto';
import * as configuracaoHelperAPI from '@/helpers/api/Configuracao';

import * as types from './types';

export const setPerfil = ({ commit, }, data) => {
    commit(types.SET_PERFIL, data);
};

export const getArtistas = async ({ commit, dispatch }, search = '') => {
    const result = await artistaHelperAPI.getArtistas(search)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_ARTISTAS, response.data.artistas);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getArtista = async ({ commit, dispatch }, search = '') => {
    const result = await artistaHelperAPI.getArtista(search)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_ARTISTA, response.data.artista);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getObrasPerfil = async ({ commit, dispatch }, params = '') => {
    const result = await obraHelperAPI.getObras(params)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_OBRAS_PERFIL, response.data.obras);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getEventosPerfil = async ({ commit, dispatch }, params = '') => {
    const result = await eventoHelperAPI.getEventos(params)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_EVENTOS_PERFIL, response.data.eventos);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getExposicoesPerfil = async ({ commit, dispatch }, params = '') => {
    const result = await exposicaoHelperAPI.getExposicoes(params)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_EXPOSICOES_PERFIL, response.data.exposicoes);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getTextosPerfil = async ({ commit, dispatch }, params = '') => {
    const result = await textoHelperAPI.getTextos(params)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_TEXTOS_PERFIL, response.data.textos);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};


export const setCurrentScroll = ({ commit }, params) => {
    commit(types.SET_CURRENT_SCROLL, params);
};

export const doUpdateArtista = async ({ dispatch }, params) => {
    let username = (typeof params.artista.username !== 'undefined') ? params.artista.username : params.artista.get('username');
    const result = await artistaHelperAPI.updateArtista(params, username)
          .then((response) => {
              if (response.data.status === "true") {
                  dispatch('auth/doUpdateUser', response.data.usuario, { root: true });
                  const loggedUser = JSON.parse(localStorage.getItem('usuario')).username;
                  if (loggedUser === response.data.usuario.username) {
                      localStorage.setItem('usuario', JSON.stringify(response.data.usuario));
                  }
                return JSON.stringify(response.data.usuario);
              } else {
                  return response.data;
              }
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
    return result;
};

export const doUploadAvatar = async ({ dispatch }, params) => {
    let username = (typeof params.username !== 'undefined') ? params.username : params.get('username');
    const result = await artistaHelperAPI.uploadAvatarArtista(params, username)
          .then((response) => {
              if (response.data.status === 'false') {
                  if (typeof response.data.upload_limit !== "undefined") {
                      dispatch('global/setProgressBar', -1, { root: true });
                      dispatch('global/setProgressBarTotal', 0, { root: true });
                  }
                  dispatch(
                      `noticias/mensagemErro`,
                      response.data.mensagem,
                      { root: true },
                  );
              } else if (response.data.status === "true"
                         && typeof response.data.part !== "undefined"
                         && response.data.verified === "true"
                        ) {
                  // parte recebida - atualiza progress bar
                  dispatch('global/setProgressBar', 1, { root: true });
              } else {
                  // recebeu arquivo inteiro
                  dispatch('global/setProgressBar', 1, { root: true });
                  dispatch('auth/updateMediaTemp', response.data.media, { root: true });
              }
            return response.data;
          }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
    return result;
};

export const doGetAvailableTabs = ({ commit, dispatch }, params) => {
    const result = configuracaoHelperAPI.getAvailableContent(params.profile, params.username, params.is_edit)
          .then((response) => {
              if (response.data.status === "true") {
                  commit(types.SET_AVAILABLE_TABS, response.data.available_content)
              }
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
            );
          });

    return result;
};

export const getEditorial = ({ commit }, params) => {
    commit(types.SET_EDITORIAL, params);
    return true;
};
