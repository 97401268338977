export const carrinho = state => state.carrinho;
export const carrinhos = state => state.carrinhos;
export const endereco = state => state.endereco;
export const enderecos = state => state.enderecos;
export const dadosPagamento = state => state.dadosPagamento;
export const sessionId = state => state.sessionId;
export const paymentMethods = state => state.paymentMethods;
export const brand = state => state.brand;
export const brandUrl = state => state.brandUrl;
export const installments = state => state.installments;
