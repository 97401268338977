import * as types from "./types";

export const setNumberOfColumns = ({ commit }, params) => {
    commit(types.SET_NUMBER_OF_COLUMNS, params);
};

export const setLocalItems = ({ commit }, params) => {
    commit(types.SET_LOCAL_ITEMS, params);
};

export const setColumnsList = ({ commit }, params) => {
    commit(types.SET_COLUMNS_LIST, params);
};
