import * as types from './types';

export const state = {
    evento: {},
    eventos: [],
    participantes: [],
    eventosPerfil: [],
    updatedUUID: "",
    images: {},
    interruptUpload: false,
    localEventos: [],
    total: 0,
    currentSearch: "",
}

export const mutations = {
    [types.CREATE_EVENTO](state, data) {
        state.evento = data;
    },
    [types.GET_EVENTO](state, data) {
        state.evento = data;
    },
    [types.SET_EVENTOS](state, data) {
        state.eventos = data;
    },
    [types.GET_PARTICIPANTES](state, data) {
        state.participantes = data;
    },
    [types.UPDATE_EVENTO](state, data) {
        state.evento = data;
    },
    [types.DELETE_EVENTO](state) {
        state.evento = '';
        state.participantes = [];
    },
    [types.CLEAR_EVENTO](state) {
        state.evento = '';
        state.participantes = [];
    },
    [types.SET_EVENTOS_PERFIL](state, data) {
        state.eventosPerfil = data;
    },
    [types.SET_UPDATED_UUID](state, data) {
        state.updatedUUID = data;
    },
    [types.SET_IMAGES](state, data) {
        state.images = data;
    },
    [types.SET_TOTAL_EVENTOS](state, data) {
        state.total = data;
    },
    [types.SET_LOCAL_EVENTOS](state, data) {
        state.localEventos = data;
    },
    [types.SET_CURRENT_SEARCH](state, data) {
        state.currentSearch = data;
    },
};
