export default [
    {
        path: '/rede/:id/editar',
        name: 'Editar Perfil',
        component: () => import(/* webpackChunkName: "parceiro-editar-perfil-view" */ './views/EditarPerfil'),
    },
    {
        path: '/rede/:id',
        name: 'Perfil',
        component: () => import(/* webpackChunkName: "parceiro-perfil-view" */ './views/Perfil'),
    },
    {
        path: '/rede/:id/:secao',
        name: 'Perfil',
        component: () => import(/* webpackChunkName: "parceiro-perfil-view" */ './views/Perfil'),
    },
];
