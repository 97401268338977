export default [
    {
        path: '/perfis/listagem',
        name: 'Listagem de perfis',
        component: () => import(/* webpackChunkName: "perfis-listagem-view" */ './views/Listagem'),
    },
    {
        path: '/perfis/listagem/:tipo_perfil',
        name: 'Listagem de perfis',
        component: () => import(/* webpackChunkName: "perfis-listagem-view" */ './views/Listagem'),
    },
    {
        path: '/editorial/:id',
        name: 'Perfil editorial',
        component: () => import(/* webpackChunkName: "perfis-editorial-view" */ './views/Editorial'),
    },
    {
        path: '/editorial/:id/editar',
        name: 'Editar perfil editorial',
        component: () => import(/* webpackChunkName: "perfis-editorial-editar" */ './views/EditorialEditar'),
    }
];
