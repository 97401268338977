import * as types from './types';
import * as textoHelperAPI from '@/helpers/api/Texto';


export const doCreateTexto = ({ commit, dispatch }, params) => {
    const result = textoHelperAPI.createTexto(params)
        .then((response) => {
            commit(types.CREATE_TEXTO, response.data.texto);
            if (response.data.status === 'false') {
                dispatch(
                    `noticias/mensagemErro`,
                    response.data.mensagem,
                    { root: true },
                );
            }
            return response.data;
        });
    return result;
};

export const clearTexto = ({ commit }) => {
    commit(types.GET_TEXTO, {});
};

export const getTexto = ({ commit, dispatch }, uuid) => {
    const result = textoHelperAPI.getTexto(uuid)
          .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_TEXTO, response.data.texto);
            } else {
                return false;
            }
            return response.data;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getTextos = ({ commit, dispatch }, params) => {
    const result = textoHelperAPI.getTextos(params)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_TEXTOS, response.data.textos);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const doUploadTexto = ({ commit, dispatch, state }, params) => {
    const result = textoHelperAPI.uploadTexto(params, params.uuid)
          .then((response) => {
              if (response.data.status === 'false') {
                  if (typeof response.data.upload_limit !== "undefined") {
                      dispatch('global/setProgressBar', -1, { root: true});
                      dispatch('global/setProgressBarTotal', 0, { root: true});
                  }
                  dispatch(
                      `noticias/mensagemErro`,
                      response.data.mensagem,
                      { root: true },
                  );
              } else if (response.data.status === "true"
                         && typeof response.data.part !== "undefined"
                         && response.data.verified === "true"
                        ) {
                  // parte recebida - atualiza progress bar
                  if (state.interruptUpload) {
                      dispatch('global/setProgressBar', 0, { root: true});
                  }
                  dispatch('global/setProgressBar', 1, { root: true});
              } else {
                  // recebeu arquivo inteiro
                  if (state.interruptUpload) {
                      dispatch('global/setProgressBar', 0, { root: true});
                      commit(types.INTERRUPT_UPLOAD, false);
                  } else {
                      dispatch('global/setProgressBar', 1, { root: true});
                      commit(types.CREATE_TEXTO, response.data.texto);
                  }
              }
              return response.data;
          }).catch((e) => {
              console.log(e);
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};

export const doUpdateTexto = ({ commit, dispatch }, params) => {
    const result = textoHelperAPI.updateTexto(params, params.uuid)
          .then((response) => {
              if (response.data.status === 'false') {
                dispatch(
                    `noticias/mensagemErro`,
                    response.data.mensagem,
                    { root: true },
                );
              }
              if (response.data.status === "true") {
                  commit(types.UPDATE_TEXTO, response.data.texto);
              }
              return response.data;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};

export const deleteTexto = async ({ commit, dispatch }, params) => {
    const result = await textoHelperAPI.deleteTexto(params)
          .then((response) => {
              if (response.data.status === 'true') {
                  commit(types.DELETE_TEXTO);
                  return true;
              }
              return false;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};
