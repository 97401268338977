import * as api from './base';

export const createCarrinho = (params) => {
    const path = '/carrinho';
    return api.postRequest(path, params);
};

export const getCarrinho = (params) => {
    const path = '/carrinho/';
    return api.getRequest(path, params.username, params.uuid);
};

export const getCarrinhos = (params) => {
    const path = '/carrinho';
    return api.getRequest(path + api.parseQueryParams(params));
};

export const deleteCarrinho = (uuid) => {
    const path = '/carrinhos';
    return api.deleteRequest(path, uuid);
};

export const updateCarrinho = (params, uuid) => {
    const path = `/carrinho/${params.responsavel}`;
    return api.putRequest(path, params, uuid);
};

export const createEndereco = (params) => {
    const path = `/enderecos/${params.responsavel}`;
    return api.postRequest(path, params);
};

export const updateEndereco = (params, uuid) => {
    const path = `/enderecos/${params.responsavel}`;
    return api.putRequest(path, params, uuid);
};

export const getEndereco = (params) => {
    const path = `/enderecos/${params.responsavel}/${params.uuid}`;
    return api.getRequest(path);
};

export const getEnderecos = (params) => {
    const path = `/enderecos/${params.responsavel}`;
    return api.getRequest(path + api.parseQueryParams(params));
};

export const deleteEndereco = (params) => {
    const path = `/enderecos/${params.responsavel}`;
    return api.deleteRequest(path, params.uuid);
};

export const doStartSessionPagSeguro = () => {
    const path = '/obter_sessao';
    try {
        return api.getRequest(path);
    } catch(e) {
        console.log(e);
        return false;
    }
};
