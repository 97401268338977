import * as api from './base';

export const getUsers = (params) => {
    const path = '/users';
    return api.getRequest(path + api.parseQueryParams(params));    
};

export const getInviteCodes = (params) => {
    const path = '/invite_codes';
    return api.getRequest(path + api.parseQueryParams(params));
};

export const createInviteCode = (params) => {
    const path = '/invite_codes';
    return api.postRequest(path, params);
};

export const userAddProfile = (params) => {
    const path = '/user/add_profile';
    return api.postRequest(path, params);
};
