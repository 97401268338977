import * as exposicaoHelperAPI from '@/helpers/api/Exposicao';
import * as types from './types';

export const getExposicoes = async ({ commit, dispatch }, params) => {
    const result = await exposicaoHelperAPI.getExposicoes(params)
          .then((response) => {
              if (response.data.status === "true") {
                  commit(types.SET_EXPOSICOES, response.data.exposicoes);
              } else {
                  return false;
              }
              return true;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
        });
    return result;
};

export const getExposicoesPerfil = async ({ commit, dispatch }, params = '') => {
    const result = await exposicaoHelperAPI.getExposicoes(params)
          .then((response) => {
            if (response.data.status === "true") {
                commit(types.SET_EXPOSICOES_PERFIL, response.data.exposicoes);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getExposicao = async ({ commit, dispatch }, params) => {
    const result = await exposicaoHelperAPI.getExposicao(params.uuid)
          .then((response) => {
              if (response.data.status === "true") {
                  commit(types.SET_EXPOSICAO, response.data.exposicao);
              } else {
                  return false;
              }
              return true;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
        });
    return result;
};

export const doCreateExposicao = ({ commit, dispatch }, params) => {
    exposicaoHelperAPI.createExposicao(params)
                   .then((response) => {
                       commit(types.CREATE_EXPOSICAO, response.data.exposicao);
                       dispatch(
                         'noticias/mensagemSucesso',
                         response.data.mensagem,
                         { root: true },
                       );
                   });
};

export const doUpdateExposicao = async ({ commit, dispatch }, params) => {
    const result = await exposicaoHelperAPI.updateExposicao(params, params.uuid)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.UPDATE_EXPOSICAO, response.data.exposicao);
                commit(types.SET_LOCAL_EXPOSICOES, []);
                commit(types.SET_EXPOSICOES, []);
                dispatch(
                    'noticias/mensagemSucesso',
                    response.data.mensagem,
                    { root: true },
                );
                return response.data;
            } else {
                return false;
            }
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
    return result;
};

export const deleteExposicao = ({ commit, dispatch }, params) => {
    const result = exposicaoHelperAPI.deleteExposicao(params)
          .then((response) => {
              if (response.data.status === 'true') {
                  commit(types.DELETE_EXPOSICAO);
                  return true;
              }
              return false;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const doUploadImagemExposicao = async ({ commit, dispatch }, params) => {
    const result = await exposicaoHelperAPI.updateExposicao(params, params.get('uuid'))
          .then((response) => {
              if (response.data.status === "true") {
                  commit(types.UPDATE_EXPOSICAO, response.data.exposicao);
                  return response.data;
              } else {
                  return false;
              }
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};

export const setImages = ({ commit}, data) => {
    commit(types.SET_IMAGES, data);
};

export const setLocalExposicoes = ({ commit }, params) => {
    commit(types.SET_LOCAL_EXPOSICOES, params);
};

export const cleanExposicao = ({ commit }) => {
  commit(types.SET_EXPOSICAO, {});
};

export const cleanExposicoes = ({ commit }) => {
  commit(types.SET_EXPOSICOES, []);
  commit(types.SET_LOCAL_EXPOSICOES, []);
};
