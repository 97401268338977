import * as api from './base';

export const getParceiros = (params) => {
    const path = '/parceiros';
    return api.getRequest(path + api.parseQueryParams(params));
};

export const getParceiro = (params) => {
    console.log('getParceiro api');
    
    const path = `/parceiros/${params.username}`;
    return api.getRequest(path);
};

export const updateParceiro = (params, id) => {
    const path = `/parceiros`;
    return api.putRequest(path, params, id);
};

export const uploadAvatarParceiro = (params, id) => {
    const path = `/parceiros`;
    return api.putRequest(path, params, id, api.configUpload);
};

export const updateParceirosView = () => {
    const path = '/parceiros/view/refresh';
    return api.getRequest(path);
}
