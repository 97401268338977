import * as api from './base';

export const getCities = (city) => {
    const path = '/localizacao/city/';
    return api.getRequest(path, city);
};

export const getCitiesNotAggregated = (city) => {
    const path = '/localizacao/city_not_aggregated/';
    return api.getRequest(path, city);
};

export const getCityByCode = (city_code) => {
    const path = '/localizacao/city_code/';
    return api.getRequest(path, city_code);
};

export const getLocalitiesByCity = (params) => {
    const path = `/localizacao/locality_by_city/${params.city_mode}/${params.city}/${params.locality_mode}/${params.locality}`;
    return api.getRequest(path);
};
export const getLocalityByCep = (cep) => {
    const path = '/localizacao/locality_cep/';
    return api.getRequest(path, cep);
};
export const getLocalityByObjectId = (object_id) => {
    const path = '/localizacao/locality_object_id/';
    return api.getRequest(path, object_id);
};

export const getPlaces = (params) => {
    const path = "/localizacao/places";
    return api.getRequest(path + api.parseQueryParams(params));
};
