export default [
    {
        path: '/',
        name: 'BoasVindas',
        component: () => import(/* webpackChunkName: "boas-vindas-view" */ './views/BoasVindas'),
    },
    {
        path: '/home',
        name: 'Página Inicial',
        component: () => import(/* webpackChunkName: "pagina-inicial-view" */ './views/Inicial'),
        beforeEnter: (to, from, next) => {
            if (to.path === '/home') {
                // Cria redirecionamento
                const paginaInicial = "/obras/listagem"
                next(paginaInicial);
            } else {
                next();
            }
        },
    },
    {
        path: '/offline',
        name: 'PaginaOffline',
        component: () => import(/* webpackChunkName: "pagina-offline-view" */ './views/PaginaOffline'),
    },
    {
        path: '/tutoriais',
        name: 'Tutoriais',
        component: () => import(/* webpackChunkName: "paginas-tutoriais-view" */ './views/Tutoriais'),
    },
    {
        path: '/politica_de_privacidade',
        name: 'Política de privacidade',
        component: () => import(/* webpackChunkName: "politica-privacidade-view" */ './views/PoliticaPrivacidade'),
    },

];
