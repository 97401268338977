import * as api from './base';

export const getArtistas = (params) => {
    const path = '/artistas';
    return api.getRequest(path + api.parseQueryParams(params));
};

export const getArtista = (params) => {
    const path = `/artistas/${params.username}`;
    return api.getRequest(path);
};

export const updateArtista = (params, id) => {
    const path = `/artistas`;
    return api.putRequest(path, params, id);
};

export const uploadAvatarArtista = (params, id) => {
    const path = `/artistas`;
    return api.putRequest(path, params, id, api.configUpload);
};

export const updateArtistasView = () => {
    const path = '/artistas/view/refresh';
    return api.getRequest(path);
}
