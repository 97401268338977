const ExposicaoView = () => import(/* webpackChunkName: "exposicao-view" */ './views/Exposicao');
const ExposicaoNovoView = () => import(/* webpackChunkName: "exposicao-novo-view" */ './views/Novo');
const ExposicaoEditarView = () => import(/* webpackChunkName: "exposicao-editar-view" */ './views/Editar');
const ExposicaoListagemView = () => import(/* webpackChunkName: "exposicao-listagem-view" */ './views/Listagem');

export default [
    {
        path: '/mostras/listagem',
        name: 'Listagem de mosdtras',
        component: ExposicaoListagemView,
    },
    {
        path: '/mostras/novo',
        name: 'Nova mostra',
        component: ExposicaoNovoView,
    },
    {
        path: '/mostras/:uuid',
        name: 'Mostra',
        component: ExposicaoView,
    },
    {
        path: '/mostras/:uuid',
        name: 'Mostra',
        component: ExposicaoView,
    },
    {
        path: '/mostras/:uuid/editar',
        name: 'Editar mostra',
        component: ExposicaoEditarView,
    },
];
