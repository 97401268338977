import * as artistaHelperAPI from '@/helpers/api/Artista';
import * as obraHelperAPI from '@/helpers/api/Obra';
import * as types from './types';

export const getArtistas = async ({ commit, dispatch }, search = '') => {
    commit(types.GET_ARTISTAS, [])
    const result = await artistaHelperAPI.getArtistas(search)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_ARTISTAS, response.data.artistas);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getArtista = async ({ commit, dispatch }, search = '') => {
    const result = await artistaHelperAPI.getArtista(search)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_ARTISTA, response.data.artista);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getObrasArtista = async ({ commit, dispatch }, params = '') => {
    const result = await obraHelperAPI.getObras(params)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_OBRAS_ARTISTA, response.data.obras);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const setCurrentScroll = ({ commit }, params) => {
    commit(types.SET_CURRENT_SCROLL, params);
};

export const doUpdateArtista = async ({ commit, dispatch }, params) => {
    let username = (typeof params.artista.username !== 'undefined') ? params.artista.username : params.artista.get('username');
    const result = await artistaHelperAPI.updateArtista(params, username)
          .then((response) => {
              if (response.data.status === "true") {
                  dispatch('auth/doUpdateUser', response.data.usuario, { root: true });
                  const loggedUser = JSON.parse(localStorage.getItem('usuario')).username;
                  if (loggedUser === response.data.usuario.username) {
                      localStorage.setItem('usuario', JSON.stringify(response.data.usuario));
                  }
                  commit(types.SET_ARTISTA, response.data.usuario);
                return JSON.stringify(response.data.usuario);
              } else {
                  return response.data;
              }
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
    return result;
};

export const doUploadAvatar = async ({ dispatch }, params) => {
    let username = (typeof params.username !== 'undefined') ? params.username : params.get('username');
    const result = await artistaHelperAPI.uploadAvatarArtista(params, username)
          .then((response) => {
              if (response.data.status === 'false') {
                  if (typeof response.data.upload_limit !== "undefined") {
                      dispatch('global/setProgressBar', -1, { root: true });
                      dispatch('global/setProgressBarTotal', 0, { root: true });
                  }
                  dispatch(
                      `noticias/mensagemErro`,
                      response.data.mensagem,
                      { root: true },
                  );
              } else if (response.data.status === "true"
                         && typeof response.data.part !== "undefined"
                         && response.data.verified === "true"
                        ) {
                  // parte recebida - atualiza progress bar
                  dispatch('global/setProgressBar', 1, { root: true });
              } else {
                  // recebeu arquivo inteiro
                  dispatch('global/setProgressBar', 1, { root: true });
                  dispatch('auth/updateMediaTemp', response.data.media, { root: true });
              }
            return response.data;
          }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
    return result;
};
