import * as types from './types';

export const state = {
    users: [],
    inviteCode: "",
    inviteCodes: [],
    currentUser: {},
};

export const mutations = {
    [types.SET_USERS](state, data) {
        state.users = data;
    },
    [types.SET_INVITE_CODES](state, data) {
        state.inviteCodes = data;
    },
    [types.SET_INVITE_CODE](state, data) {
        state.inviteCode = data;
    },
    [types.SET_CURRENT_UPDATE_USER](state, data) {
        state.currentUser = data;
    },
};
