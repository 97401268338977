const AdminView = () => import(/* webpackChunkName: "admin-view" */ './views/Admin');
const ListMessagesView = () => import(/* webpackChunkName: "list-messages-view" */ './views/ListMessages');
const ListUsersView = () => import(/* webpackChunkName: "list-users-view" */ './views/ListUsers');
const GetInviteCodeView = () => import(/* webpackChunkName: "get-invite-code-view" */ './views/GetInviteCode');

export default [
    {
        path: '/admin',
        name: 'Admin',
        component: AdminView,
    },
    {
        path: '/admin/list_messages',
        name: 'Listar mensagens',
        component: ListMessagesView,
    },
    {
        path: '/admin/list_users',
        name: 'Listar usuários',
        component: ListUsersView,
    },
    {
        path: '/admin/get_invite_code',
        name: 'Criar invite code',
        component: GetInviteCodeView,
    },
];
