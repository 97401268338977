import * as types from "./types";

export const state = {
    numberOfColumns: 0,
    localItems: [],
    columnsList: [],
};

export const mutations = {
    [types.SET_NUMBER_OF_COLUMNS](state, data) {
        state.numberOfColumns = data;
    },
    [types.SET_LOCAL_ITEMS](state, data) {
        state.localItems = data;
    },
    [types.SET_COLUMNS_LIST](state, data) {
        state.columnsList = data;
    },
};
