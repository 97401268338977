export const usuario = state => state.usuario;
export const token = state => state.token;
export const isLoggedIn = (state) => {
    return ((state.token !== '') && (state.usuario))
};
export const isTemporaryPassword = state => state.isTemporaryPassword;
export const tokenExpiresOn = state => state.tokenExpiresOn;
export const email = state => state.email;
export const validationCode = state => state.validationCode;
export const isValidResetPasswordLink = state => state.isValidResetPassword;
export const isEmailConfirmed = state => state.isEmailConfirmed;
export const usernameAvailable = state => state.usernameAvailable;
export const emailAvailable = state => state.emailAvailable;
export const usernameExistsMessage = state => state.usernameExistsMessage;
export const usernameExists = state => state.usernameExists;
export const mediaTemp = state => state.mediaTemp;
export const images = state => state.images;
export const filterConfigurations = state => state.filterConfigurations;
export const deviceUuid = state => state.deviceUuid;
export const showDebug = state => state.showDebug;
export const createUserInviteCode = state => state.createUserInviteCode;
export const exibeExpiracao = state => state.exibeExpiracao;
