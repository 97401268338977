import * as eventoHelperAPI from '@/helpers/api/Evento';
import * as types from './types';


export const doCreateEvento = ({ commit }, params) => {
  const result = eventoHelperAPI.createEvento(params)
        .then((response) => {
            commit(types.CREATE_EVENTO, response.data.evento);
            return response.data;
        });
  return result;
};

export const doUpdateEvento = ({ commit, dispatch }, params) => {
    const result = eventoHelperAPI.updateEvento(params, params.uuid)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.UPDATE_EVENTO, response.data.evento);
                return response.data;
            } else {
                return false;
            }
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
    return result;
};

export const getEvento = ({ commit, dispatch }, params) => {
    const result = eventoHelperAPI.getEvento(params.uuid)
          .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_EVENTO, response.data.evento);
            } else {
                return false;
            }
            return response.data;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getEventosPerfilEdit = ({ commit, dispatch }, params) => {
    const result = eventoHelperAPI.getEventos(params)
          .then((response) => {
              if (response.data.status === "true") {
                commit(types.SET_EVENTOS_PERFIL, response.data.eventos);
              } else {
                  return false;
              }
              return true;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
        });
    return result;
};

export const getEventosPerfil = ({ commit, dispatch }, params = '') => {
    const result = eventoHelperAPI.getEventosOcorrencia(params)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.SET_EVENTOS_PERFIL, response.data.eventos);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getEventosOcorrencia = ({ commit, dispatch }, params) => {
    const result = eventoHelperAPI.getEventosOcorrencia(params)
          .then((response) => {
              if (response.data.status === "true") {
                if (Object.prototype.hasOwnProperty.call(response.data, 'total')) {
                    commit(types.SET_TOTAL_EVENTOS, response.data.total);
                }
                commit(types.SET_EVENTOS, response.data.eventos);
              } else {
                  return false;
              }
              return true;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
        });
    return result;
};

export const getParticipantes = ({ commit, dispatch }, params) => {
    eventoHelperAPI.getParticipantes(params)
        .then((response) => {
            commit(types.GET_PARTICIPANTES, response.data);
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
};

export const deleteEvento = ({ commit, dispatch }, params) => {
    const result = eventoHelperAPI.deleteEvento(params)
          .then((response) => {
              if (response.data.status === 'true') {
                  commit(types.DELETE_EVENTO);
                  return true;
              }
              return false;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const clearEvento = ({ commit }) => {
    commit(types.CLEAR_EVENTO);
};

export const setImages = ({ commit}, data) => {
    commit(types.SET_IMAGES, data);
};

export const setUpdatedUUID = ({ commit}, data) => {
    commit(types.SET_UPDATED_UUID, data);
};

export const setLocalEventos = ({ commit }, params) => {
    commit(types.SET_LOCAL_EVENTOS, params);
};

export const setCurrentSearch = ({ commit }, params) => {
    commit(types.SET_CURRENT_SEARCH, params);
};
