import * as types from './types';

export const state = {
    carrinho: {},
    carrinhos: [],
    endereco: {},
    enderecos: [],
    dadosPagamento: {},
    sessionId: "",
    paymentMethods: {},
    brand: "",
    brandUrl: "",
    installments: [],
};

export const mutations = {
    [types.CREATE_CARRINHO](state, data) {
        state.carrinho = data;
    },
    [types.GET_CARRINHO](state, data) {
        state.carrinho = data;
    },
    [types.GET_CARRINHOS](state, data) {
        state.carrinhos = data;
    },
    [types.UPDATE_CARRINHO](state, data) {
        state.carrinho = data;
    },
    [types.DELETE_CARRINHO](state) {
        state.carrinho = '';
    },
    [types.CREATE_ENDERECO](state, data) {
        state.endereco = data;
    },
    [types.GET_ENDERECO](state, data) {
        state.endereco = data;
    },
    [types.GET_ENDERECOS](state, data) {
        state.enderecos = data;
    },
    [types.UPDATE_ENDERECO](state, data) {
        state.endereco = data;
    },
    [types.DELETE_ENDERECO](state) {
        state.endereco = '';
    },
    [types.UPDATE_DADOS_PAGAMENTO](state, data) {
        state.dadosPagamento = data;
    },
    [types.SET_SESSION_ID](state, data) {
        state.sessionId = data;
    },
    [types.SET_PAYMENT_METHODS](state, data) {
        state.paymentMethods = data;
    },
    [types.SET_BRAND](state, data) {
        state.brand = data;
    },
    [types.SET_BRAND_URL](state, data) {
        state.brandUrl = data;
    },
    [types.SET_INSTALLMENTS](state, data) {
        state.installments = data;
    },
};
