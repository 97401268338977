import * as types from './types';

export const state = {
    exposicao: {},
    exposicoes: [],
    exposicoesPerfil: [],
    localExposicoes: [],
    images: {},
}

export const mutations = {
    [types.CREATE_EXPOSICAO](state, data) {
        state.exposicao = data;
    },
    [types.SET_EXPOSICAO](state, data) {
        state.exposicao = data;
    },
    [types.SET_EXPOSICOES](state, data) {
        state.exposicoes = data;
    },
    [types.SET_EXPOSICOES_PERFIL](state, data) {
        state.exposicoesPerfil = data;
    },
    [types.UPDATE_EXPOSICAO](state, data) {
        state.exposicao = data;
    },
    [types.UPDATE_ELEMENTO](state, data) {
        state.exposicao.secoes[data.indexSecao].elementos.push({ ...data });
    },
    [types.DELETE_EXPOSICAO](state) {
        state.exposicao = "";
    },
    [types.MOVE_ELEMENTO](state, data) {
        let arrElementos  = state.exposicao.secoes[data.indexSecao].elementos;
        let elementToMove = arrElementos.splice(data.currentIndex, 1)[0];

        arrElementos.splice(data.targetIndex, 0, elementToMove);

        state.exposicao.secoes[data.indexSecao].elementos = arrElementos;
    },
    [types.SET_LOCAL_EXPOSICOES](state, data) {
        state.localExposicoes = data;
    },
    [types.SET_IMAGES](state, data) {
        state.images = data;
    },
};
