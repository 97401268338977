// import Vue from "vue";
import * as types from "./types";

export const state = {
    favorito: false,
}

export const mutations = {
    [types.FAVORITO](state, data) {
        state.favorito = data;
    },
};
