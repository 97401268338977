import * as lojaHelperAPI from '@/helpers/api/Loja';
import * as types from './types';


export const doCreateCarrinho = async ({ commit, dispatch }, params) => {
    const result = await lojaHelperAPI.createCarrinho(params)
        .then((response) => {
            commit(types.CREATE_CARRINHO, response.data.carrinho);
            if (response.data.status === 'false') {
                dispatch(
                    `noticias/mensagemErro`,
                    response.data.mensagem,
                    { root: true },
                );
            }
            return response.data;
        });
    return result;
};


export const getCarrinho = async ({ commit, dispatch }, params) => {
    const result = await lojaHelperAPI.getCarrinho(params.uuid)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_CARRINHO, response.data.carrinho);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};


export const obterCarrinho = async ({ commit, dispatch }, params) => {
    const result = await lojaHelperAPI.getCarrinhos(params)
        .then((response) => {
            if (response.data.status === "true") {
                if (response.data.carrinhos.length > 0) {
                    commit(types.GET_CARRINHO, response.data.carrinhos[0]);
                } else {
                    commit(types.GET_CARRINHO, {});
                }
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const doUpdateCarrinho = async ({ commit, dispatch }, params) => {
    const result = await lojaHelperAPI.updateCarrinho(params, params.uuid)
          .then((response) => {
              if (response.data.status === 'false') {
                dispatch(
                    `noticias/mensagemErro`,
                    response.data.mensagem,
                    { root: true },
                );
              }
              if (response.data.status === "true") {
                  commit(types.UPDATE_CARRINHO, response.data.carrinho);
              }
              return response.data.carrinho;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};

export const doCreateEndereco = async ({ commit, dispatch }, params) => {
    const result = await lojaHelperAPI.createEndereco(params)
        .then((response) => {
            commit(types.CREATE_ENDERECO, response.data.endereco);
            if (response.data.status === 'false') {
                dispatch(
                    `noticias/mensagemErro`,
                    response.data.mensagem,
                    { root: true },
                );
            }
            return response.data;
        });
    return result;
};

export const getEndereco = async ({ commit, dispatch }, params) => {
    const result = await lojaHelperAPI.getEndereco(params)
          .then((response) => {
              if (response.data.status === "true") {
                  commit(types.GET_ENDERECO, response.data.endereco);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getEnderecos = async ({ commit, dispatch }, params) => {
    const result = await lojaHelperAPI.getEnderecos(params)
        .then((response) => {
            if (response.data.status === "true") {
                if (response.data.enderecos.length > 0) {
                    commit(types.GET_ENDERECOS, response.data.enderecos);
                } else {
                    commit(types.GET_ENDERECOS, {});
                }
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const doUpdateEndereco = async ({ commit, dispatch }, params) => {
    const result = await lojaHelperAPI.updateEndereco(params, params.uuid)
          .then((response) => {
              if (response.data.status === 'false') {
                dispatch(
                    `noticias/mensagemErro`,
                    response.data.mensagem,
                    { root: true },
                );
              }
              if (response.data.status === "true") {
                  commit(types.UPDATE_ENDERECO, response.data.endereco);
              }
              return response.data.endereco;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};

export const deleteEndereco = async ({ commit, dispatch }, params) => {
    const result = await lojaHelperAPI.deleteEndereco(params)
          .then((response) => {
              if (response.data.status === 'true') {
                  commit(types.DELETE_ENDERECO);
                  return true;
              }
              return false;
          }).catch((e) => {
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
              return false;
          });
    return result;
};

export const doUpdateDadosPagamento = ({ commit }, params) => {
    commit(types.UPDATE_DADOS_PAGAMENTO, params);
};

export const doStartSessionPagSeguro = async ({ commit }) => {
    const result = await lojaHelperAPI.doStartSessionPagSeguro()
          .then((response) => {
              if (response.data.status === 'true') {
                  commit(types.SET_SESSION_ID, response.data.session_id);
              } else {
                  return false;
              }
          });
    return result;
};

export const setPaymentMethods = ({ commit }, data) => {
    commit(types.SET_PAYMENT_METHODS, data);
};

export const setBrand = ({ commit }, data) => {
    commit(types.SET_BRAND, data);
};

export const setBrandUrl = ({ commit }, data) => {
    commit(types.SET_BRAND_URL, data);
};

export const setInstallments = ({ commit }, data) => {
    console.log('actions -> set parcelas');
    commit(types.SET_INSTALLMENTS, data);
};

export const clearCard = ({ commit }) => {
    commit(types.SET_BRAND, "");
    commit(types.SET_BRAND_URL, "");
    commit(types.SET_INSTALLMENTS, "");
};
