export default [
    {
        path: '/historico/compras',
        name: 'Histórico Compras',
        component: () => import(/* webpackChunkName: "loja-historicocompras-view" */ './views/HistoricoCompras'),
    },
    {
        path: '/compras/:id',
        name: 'Detalhes Compra',
        component: () => import(/* webpackChunkName: "loja-detalhescompra-view" */ './views/DetalhesCompra'),
    },
    {
        path: '/compras/:id/cancelamento',
        name: 'Cancelamento Compra',
        component: () => import(/* webpackChunkName: "loja-cancelamentocompra-view" */ './views/CancelamentoCompra'),
    },
    {
        path: '/historico/vendas',
        name: 'Histórico Vendas',
        component: () => import(/* webpackChunkName: "loja-historicovendas-view" */ './views/HistoricoVendas'),
    },
    {
        path: '/vendas/:id',
        name: 'Detalhes Venda',
        component: () => import(/* webpackChunkName: "loja-detalhesvenda-view" */ './views/DetalhesVenda'),
    },
    {
        path: '/carrinho',
        name: 'Carrinho de Compras',
        component: () => import(/* webpackChunkName: "loja-carrinhodecompras-view" */ './views/CarrinhoDeCompras'),
    },
    {
        path: '/realizar_compra',
        name: 'Realizar compra',
        component: () => import(/* webpackChunkName: "realizar-compra-view" */ './views/RealizarCompra'),
    },
    {
        path: '/editar_endereco',
        name: 'Editar endereço',
        component: () => import(/* webpackChunkName: "editar-endereco-view" */ './views/EditarEndereco'),
    },
    {
        path: '/editar_endereco/:uuid',
        name: 'Editar endereço',
        component: () => import(/* webpackChunkName: "editar-endereco-view" */ './views/EditarEndereco'),
    },
    {
        path: '/meus_enderecos',
        name: 'Meus endereços',
        component: () => import(/* webpackChunkName: "meus-enderecos-view" */ './views/MeusEnderecos'),
    },
    {
        path: '/compra_realizada',
        name: 'Compra realizada',
        component: () => import(/* webpackChunkName: "compra-realizada-view" */ './views/CompraRealizada'),
    },
    {
        path: '/pagamento_cartao',
        name: 'Pagamento cartão',
        component: () => import(/* webpackChunkName: "pagamento-cartao-view" */ './views/PagamentoCartao'),
    },
];
