export const SET_EXPOSICAO = 'SET_EXPOSICAO';
export const SET_EXPOSICOES = 'SET_EXPOSICOES';
export const SET_EXPOSICOES_PERFIL = 'SET_EXPOSICOES_PERFIL';
export const CREATE_EXPOSICAO = 'CREATE_EXPOSICAO';
export const UPDATE_EXPOSICAO = 'UPDATE_EXPOSICAO';
export const UPDATE_ELEMENTO = 'UPDATE_ELEMENTO';
export const MOVE_ELEMENTO = 'MOVE_ELEMENTO';
export const SET_LOCAL_EXPOSICOES = 'SET_LOCAL_EXPOSICOES';
export const DELETE_EXPOSICAO = 'DELETE_EXPOSICAO';
export const SET_IMAGES = 'SET_IMAGES';
