let Constants = {
    //  install
    install(Vue) {
        //  create global Constants
        Vue.Constants = {
            USER_STATUS_WAITING_PURGE: -2,
            USER_STATUS_INACTIVE: -1,
            USER_STATUS_PENDING: 0,
            USER_STATUS_ACTIVE: 1,
            USER_ARTISTA_ACTIVE: 1,
            USER_ARTISTA_INACTIVE: -1,
            USER_PERFIL_ACTIVE: 1,
            USER_PERFIL_INACTIVE: -1,
            CLIENT_MOBILE_APP: 'mobile_app',
            CLIENT_MOBILE_BROWSER: 'mobile_browser',
            CLIENT_DESKTOP: 'desktop',
            CONN_TYPES_RATE: {
                "Connection.UNKNOWN": 1,
                "Connection.ETHERNET": 99,
                "Connection.WIFI": 99,
                "Connection.CELL_2G": 2,
                "Connection.CELL_3G": 2,
                "Connection.CELL_4G": 5,
                "Connection.CELL": 2,
                "Connection.NONE": 1,
            },
        };
    }
};

export default Constants;
