export const evento = state => state.evento;
export const eventos = state => state.eventos;
export const participantes = state => state.participantes;
export const eventosPerfil = state => state.eventosPerfil;
export const updatedUUID = state => state.updatedUUID;
export const mediaTemp = state => state.mediaTemp;
export const imageTypeSelected = state => state.imageTypeSelected;
export const images = state => state.images;
export const localEventos = state => state.localEventos;
export const total = state => state.total;
export const currentSearch = state => state.currentSearch;
