import * as api from './base';

export const favoritar = (params) => {
    const path = '/favorito';
    return api.postRequest(path, params);
};

export const getFavorito = (params) => {    
    const path = '/favorito';
    return api.getRequest(path + api.parseQueryParams(params));
};