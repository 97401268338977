export const SET_USER = 'SET_USER';
export const REGISTER_LOGIN = 'REGISTER_LOGIN';
export const LOGOUT = 'LOGOUT';
export const CREATE_USER = 'CREATE_USER';
export const EDIT_USER = 'EDIT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const VALID_RESET_PASSWORD = 'VALID_RESET_PASSWORD';
export const USERNAME_AVAILABLE = 'USERNAME_AVAILABLE';
export const EMAIL_AVAILABLE = 'EMAIL_AVAILABLE';
export const EMAIL_CONFIRMED = 'EMAIL_CONFIRMED';
export const UPDATE_MEDIA_TEMP = 'UPDATE_MEDIA_TEMP';
export const SET_IMAGES = 'SET_IMAGES';
export const UPDATE_FILTER_CONFIGURATIONS = 'UPDATE_FILTER_CONFIGURATIONS';
export const CLEAR_FILTER_CONFIGURATIONS = 'CLEAR_FILTER_CONFIGURATIONS';
export const SET_DEVICE_UUID = 'SET_DEVICE_UUID';
export const SET_IS_TEMPORARY_PASSWORD = 'SET_IS_TEMPORARY_PASSWORD';
export const SET_SHOW_DEBUG = 'SET_SHOW_DEBUG';
export const SET_CREATE_USER_INVITE_CODE = 'SET_CREATE_USER_INVITE_CODE';
export const SET_EXIBE_EXPIRACAO = 'SET_EXIBE_EXPIRACAO';
