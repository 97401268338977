import * as api from './base';

export const createObra = (params) => {
    const path = '/obras';
    return api.postRequest(path, params);
};

export const uploadObra = (params, uuid) => {
    const path = '/obras';
    return api.putRequest(path, params, uuid, api.configUpload);
};

export const updateObra = (params, uuid) => {
    const path = '/obras';
    return api.putRequest(path, params, uuid);
};

export const deleteObra = (uuid) => {
    const path = '/obras';
    return api.deleteRequest(path, uuid);
};

export const updateObraFichaTecnica = (params, uuid) => {
    const path = `/obras/${uuid}/ficha_tecnica`;
    return api.simplePutRequest(path, params, uuid);
};

export const getObra = (uuid) => {
    const path = '/obras/';
    return api.getRequest(path, uuid);
};

export const getObraFichaTecnica = (uuid) => {
    const path = `/obras/${uuid}/ficha_tecnica`;
    return api.getRequest(path);
};

export const getObras = (params) => {
    const path = '/obras';
    return api.getRequest(path + api.parseQueryParams(params));
};

export const getLinguagensFilled = () => {
    const path = '/obras/linguagens_filled';
    return api.getRequest(path);
};

export const getLinguagensCount = () => {
    const path = '/obras/linguagens_count';
    return api.getRequest(path);
};

export const updateWeight = (params, uuid) => {
    const path = `/obras/update_weight/${uuid}`;
    return api.simplePutRequest(path, params, uuid);
};

export const updateObrasView = () => {
    const path = '/obras/view/refresh';
    return api.getRequest(path);
}
