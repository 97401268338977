import store from '@/store';

export default [
    {
        path: '/obras/listagem',
        name: 'Listagem de obras',
        component: () => import(/* webpackChunkName: "obra-listagem-view" */ './views/Listagem'),
        beforeEnter: (to, from, next) => {
            if (from.path.indexOf("obras") === -1) {
                const setCurrentScroll = store._actions["obra/setCurrentScroll"][0];
                const setCurrentSearch = store._actions["obra/setCurrentSearch"][0];
                setCurrentScroll(0);
                // zera busca caso venha de outras páginas fora do contexto das obras
                setCurrentSearch("");
            }
            next();
        },
    },
    {
        path: '/obras/:id',
        name: 'Obra',
        component: () => import(/* webpackChunkName: "obra-view" */ './views/Obra'),
    },
    {
        path: '/obras/:uuid/editar',
        name: 'Editar obra',
        component: () => import(/* webpackChunkName: "obra-editar-view" */ './views/Editar'),
    },
];
