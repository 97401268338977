import * as api from './base';

export const uploadMediaTemp = (params) => {
    const path = '/media/temp';
    return api.postRequest(path, params);
};


export const deleteMediaTemp = (params) => {
    const path = '/media/temp';
    return api.queryDeleteRequest(path, api.parseQueryParams(params));
};

export const interruptUpload = (params) => {
    const path = '/media/temp';
    return api.postRequest(path, params);
};
