import * as types from './types';

export const state = {
    parceiros: [],
    parceiro: {},
    currentScroll: 0,
}

export const mutations = {
    [types.GET_PARCEIROS](state, data) {
        state.parceiros = data;
    },
    [types.GET_PARCEIRO](state, data) {
        state.parceiro = data;
    },
    [types.SET_CURRENT_SCROLL](state, data) {
        state.currentScroll = data;
    },
    [types.SET_PARCEIRO](state, data) {
        state.parceiro = data;
    },
};
