export const CREATE_EVENTO = 'CREATE_EVENTO';
export const GET_EVENTO = 'GET_EVENTO';
export const SET_EVENTOS = 'SET_EVENTOS';
export const UPDATE_EVENTO = 'UPDATE_EVENTO';
export const DELETE_EVENTO = 'DELETE_EVENTO';
export const CLEAR_EVENTO = 'CLEAR_EVENTO';
export const GET_PARTICIPANTES = 'GET_PARTICIPANTES';
export const SET_EVENTOS_PERFIL = 'SET_EVENTOS_PERFIL';
export const SET_UPDATE_IMAGE = 'SET_UPDATE_IMAGE';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_UPDATED_UUID = 'SET_UPDATED_UUID';
export const SET_LOCAL_EVENTOS = 'SET_LOCAL_EVENTOS';
export const SET_TOTAL_EVENTOS = 'SET_TOTAL_EVENTOS';
export const SET_CURRENT_SEARCH = 'SET_CURRENT_SEARCH';
