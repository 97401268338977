import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import 'vuetify/dist/vuetify.min.css'
import Constants from '@/plugins/constants';
import App from './App.vue';
import VCurrencyField from 'v-currency-field';
import Axios from 'axios';
import VueCookies from 'vue-cookies';
import VueGtag from "vue-gtag";
import './registerServiceWorker';
import {
    store,
    router,
} from './config';

/* v-currency-field */
import { VTextField } from 'vuetify/lib'  //Globally import VTextField
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true,
});

/* mart app constants */
Vue.use(Constants);

/* cookies */
Vue.use(VueCookies);

/* Google analytics */
if (process.env.NODE_ENV === "production") {
    Vue.use(VueGtag, {
        //ID PARA CONTA DA MART DO ANALYTICS
        //mart.ltda@gmail.com
        config: { id: "G-LMYBD64YZV" }
    }, router);
}

/* production tip */
Vue.config.productionTip = false

/* axios & auth */
Vue.prototype.$http = Axios;

/* starts it */
new Vue({
    el: '#app',
    vuetify,
    Constants,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
