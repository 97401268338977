import * as parceiroHelperAPI from '@/helpers/api/Parceiro';
// import * as obraHelperAPI from '@/helpers/api/Obra';
import * as types from './types';

export const getParceiros = async ({ commit, dispatch }, search = '') => {
    commit(types.GET_PARCEIROS, [])
    const result = await parceiroHelperAPI.getParceiros(search)
        .then((response) => {
            if (response.data.status === "true") {
                commit(types.GET_PARCEIROS, response.data.parceiros);
            } else {
                return false;
            }
            return true;
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
            return false;
        });
    return result;
};

export const getParceiro = async ({ commit, dispatch}, params) => {
    const result = await parceiroHelperAPI.getParceiro(params)
          .then((response) => {
              commit(types.GET_PARCEIRO, response.data.parceiro);

              return response.data;
          }).catch((e) => {
              console.log(e);
              dispatch(
                  'noticias/mensagemErro',
                  e.response.mensagem,
                  { root: true },
              );
          });
    return result;
};

export const setCurrentScroll = ({ commit }, params) => {
    commit(types.SET_CURRENT_SCROLL, params);
};

export const doUpdateParceiro = async ({ commit, dispatch }, params) => {
    let username = (typeof params.parceiro.username !== 'undefined') ? params.parceiro.username : params.parceiro.get('username');
    const result = await parceiroHelperAPI.updateParceiro(params, username)
          .then((response) => {
              if (response.data.status === "true") {
                  dispatch('auth/doUpdateUser', response.data.usuario, { root: true });
                  const loggedUser = JSON.parse(localStorage.getItem('usuario')).username;
                  if (loggedUser === response.data.usuario.username) {
                      localStorage.setItem('usuario', JSON.stringify(response.data.usuario));
                  }
                  commit(types.SET_PARCEIRO, response.data.usuario);
                return JSON.stringify(response.data.usuario);
              } else {
                  return response.data;
              }
        }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
    return result;
};

export const doUploadAvatar = async ({ dispatch }, params) => {
    let username = (typeof params.username !== 'undefined') ? params.username : params.get('username');
    const result = await parceiroHelperAPI.uploadAvatarParceiro(params, username)
          .then((response) => {
              if (response.data.status === 'false') {
                  if (typeof response.data.upload_limit !== "undefined") {
                      dispatch('global/setProgressBar', -1, { root: true });
                      dispatch('global/setProgressBarTotal', 0, { root: true });
                  }
                  dispatch(
                      `noticias/mensagemErro`,
                      response.data.mensagem,
                      { root: true },
                  );
              } else if (response.data.status === "true"
                         && typeof response.data.part !== "undefined"
                         && response.data.verified === "true"
                        ) {
                  // parte recebida - atualiza progress bar
                  dispatch('global/setProgressBar', 1, { root: true });
              } else {
                  // recebeu arquivo inteiro
                  dispatch('global/setProgressBar', 1, { root: true });
                  dispatch('auth/updateMediaTemp', response.data.media, { root: true });
              }
            return response.data;
          }).catch((e) => {
            dispatch(
                'noticias/mensagemErro',
                e.response.mensagem,
                { root: true },
            );
        });
    return result;
};
