import * as api from './base';

export const createTexto = (params) => {
    const path = '/textos';
    return api.postRequest(path, params);
};

export const getTexto = (uuid) => {
    const path = '/textos/';
    return api.getRequest(path, uuid);
};

export const getTextos = (params) => {
    const path = '/textos';
    return api.getRequest(path + api.parseQueryParams(params));
};

export const uploadTexto = (params) => {
    const path = '/textos/upload';
    return api.postRequest(path, params);
};

export const updateTexto = (params, uuid) => {
    const path = '/textos';
    return api.putRequest(path, params, uuid);
};

export const deleteTexto = (uuid) => {
    const path = '/textos';
    return api.deleteRequest(path, uuid);
};
