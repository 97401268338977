export default [
    {
        path: '/eventos/listagem',
        name: 'Listagem de eventos',
        component: () => import(/* webpackChunkName: "evento-listagem-view" */ './views/Listagem'),
    },
    {
        path: '/eventos/novo',
        name: 'Novo evento',
        component: () => import(/* webpackChunkName: "evento-novo-view" */ './views/Novo'),
    },
    {
        path: '/eventos/:uuid',
        name: 'Evento',
        component: () => import(/* webpackChunkName: "evento-view" */ './views/Evento'),
    },
    {
        path: '/eventos/:uuid/editar',
        name: 'Editar evento',
        component: () => import(/* webpackChunkName: "evento-editar-view" */ './views/Editar'),
    },
];
